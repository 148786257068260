import {Component, OnInit, ViewChild} from '@angular/core';
import {localeDe, MbscCalendar, MbscCalendarEvent, MbscEventcalendarModule, MbscEventcalendarOptions} from '@mobiscroll/angular';
import {ApiService} from '../../../../services/api.service';
import {Absent, PublicHoliday} from '../../../../../../../database-models';
import moment from 'moment-timezone';

@Component({
    selector: 'app-month-calendar',
    templateUrl: './month-calendar.component.html',
    styleUrl: './month-calendar.component.scss'
})
export class MonthCalendarComponent implements OnInit {

    @ViewChild('calendar')
    public calendar: MbscCalendar;

    public absents: Absent[] | MbscCalendarEvent[];
    public events: MbscCalendarEvent[];
    public holidays: PublicHoliday[];

    constructor(
        private api: ApiService
    ) {

    }


    public eventSettings: MbscEventcalendarOptions = {
        locale: localeDe,
        theme: 'ios',
        themeVariant: 'light',
        min: moment().startOf('months'),
        clickToCreate: false,
        dragToCreate: false,
        dragToMove: false,
        dragToResize: false,
        eventDelete: false,
        colors: [],
        view: {
            calendar: {labels: 5},
        },
        onEventClick: (event, inst) => {
            if (event.event?.actionUrl) {
                window.open(event.event.actionUrl, '_blank');
            }
        }
    };

    async ngOnInit(): Promise<void> {

        this.absents = await this.api.getAbsents().toPromise();
        let absents = this.absents.map((abs: Absent) => {
            return {
                start: abs.start,
                end: abs.end,
                color: abs.waitingForApproval ? '#b7b7b7' : abs.user?.color,
                title: (abs.waitingForApproval ? '' : (abs.absentType.emoji || '')) + ' ' + abs.absentType.name,
                allDay: true,
                user: abs.user,
                waitingForApproval: abs.waitingForApproval,
                actionUrl: '/users/' + abs.user?.id + '?tab=absence'
            } as MbscCalendarEvent;
        });

        this.holidays = await this.api.getPublicHolidays().toPromise();


        let holidays = this.holidays.map((holiday: PublicHoliday) => {
            return {
                start: moment(holiday.date).format('YYYY-MM-DD'),
                end: moment(holiday.date).format('YYYY-MM-DD'),
                allDay: true,
                title: holiday.name,
                color: '#ff0600',
                holiday: holiday
            } as MbscCalendarEvent;
        });
        for (let holiday of this.holidays) {
            this.eventSettings.colors.push({
                date: moment(holiday.date).format(),
                title: holiday.name,
                background: 'rgb(255,200,200)',
            });
        }
        let birthdays = [];
        try {
            birthdays = await this.api.getBirthdays().toPromise();
            birthdays = birthdays.map(bd => {
                bd.title = bd.user.name + ' (Geburtstag)';
                bd.color = bd.user.color;
                bd.emoji = '🎂';
                return bd;

            });
        } catch (e) {

        }


        this.events = [...absents, ...holidays, ...birthdays];

    }

}
