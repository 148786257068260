import { Injectable } from '@angular/core';
import {allCountries} from "../../../../../Shared/lists/CountryCodes";
import {allNationalities} from "../../../../../Shared/lists/NationalityCodes";

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private vatRegex = /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/

  private readonly _countries: {code: string, name: string}[] = [
    {
      code: 'DE',
      name: 'Deutschland'
    },
    {
      code: 'AT',
      name: 'Österreich'
    },
    {
      code: 'CH',
      name: 'Schweiz'
    }
  ]

  constructor() { }

  public getAllCountries(): {code: string, name: string}[] {
    return allCountries.sort((a, b) => a.name.localeCompare(b.name));
  }

  public getAllNationalities(): {code: string, nationality: string}[] {
    return allNationalities.sort((a, b) => a.nationality.localeCompare(b.nationality));
  }


  public get countries(): { code: string; name: string }[] {
    return this._countries;
  }


  public checkVATRegex(vat: string): boolean {
    console.log(this.vatRegex.test(vat))
    return this.vatRegex.test(vat);
  }

}
