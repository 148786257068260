<nz-spin [nzSpinning]="!absentForm">


  <form class="m-t-10" nz-form nzLayout="vertical" [formGroup]="absentForm" *ngIf="absentForm">


    <div nz-row nzGutter="16">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzFor="absentTypeId">Grund der Abwesenheit</nz-form-label>
          <nz-form-control>
            <nz-select [nzDisabled]="!!absent.id" formControlName="absentTypeId" class="w-100">
              <nz-option *ngFor="let type of absentTypes" nzCustomContent [nzLabel]="type.emoji + ' ' + type.name"
                         [nzValue]="type.id">
                {{ type.emoji + ' ' + type.name }}
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzFor="user">Mitarbeiter</nz-form-label>
          <nz-form-control>
            <app-username-avatar [user]="user"></app-username-avatar>
          </nz-form-control>
        </nz-form-item>
      </div>

    </div>

    <ng-container *ngIf="absentForm.get('absentTypeId')?.value === 'LEAVE' && !absent.id">
      <nz-form-item>
        <nz-form-label>Urlaubsanspruch</nz-form-label>
        <nz-form-control>
          <span class="color-red" *ngIf="!user.vacationClaims || user.vacationClaims.length <= 0">Kein Urlaubsanspruch erfasst! Bitte erfassen Sie zuerst die Ansprüche für diesen Benutzer.</span>
          <nz-tag *ngFor="let vacationClaim of user.vacationClaims" [hidden]="vacationClaim.expired">
            <strong>{{ vacationClaim.year }}:</strong> {{ vacationClaim.remaining }} Tage
          </nz-tag>

        </nz-form-control>
      </nz-form-item>

    </ng-container>

    <div nz-row nzGutter="16">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzFor="daterange">Datum / Zeitraum</nz-form-label>
          <nz-form-control>
            <mbsc-datepicker [controls]="['calendar']"
                             [disabled]="!!absent.id"
                             [select]="absentForm.get('halfDay').value ? 'date' : 'range'"
                             [colors]="color"
                             [labels]="label"
                             [invalid]="rangeSelected"
                             [calendarSize]="1"
                             [showRangeLabels]="true"
                             [inRangeInvalid]="true"
                             [rangeEndInvalid]="false"
                             theme="ios"
                             inputStyle="outline"
                             formControlName="daterange"
                             calendarType="month"
                             returnFormat="jsdate"
            >


            </mbsc-datepicker>

          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-label nzFor="halfDay">Option</nz-form-label>
          <nz-form-control>
            <label [nzDisabled]="!!absent.id" nz-checkbox formControlName="halfDay">halber Tag</label>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

      <nz-form-item *appIsAdmin>
        <nz-form-control>
          <nz-form-label nzFor="approvalState">Status des Antrags</nz-form-label>
          <nz-radio-group [nzDisabled]="absent.readonly" formControlName="approvalState">
            <label nz-radio-button nzValue="approved"><span class="color-green"><i nz-icon nzType="check"
                                                                                   nzTheme="outline"></i> Genehmigt</span>
            </label>
            <label nz-radio-button nzValue="open"><span class="color-orange"><i nz-icon nzType="question"
                                                                                nzTheme="outline"></i> Ausstehend</span>
            </label>
            <label nz-radio-button nzValue="rejected"><span class="color-red"><i nz-icon nzType="close"
                                                                                 nzTheme="outline"></i> Abgelehnt</span>
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="note">Notiz</nz-form-label>
        <nz-form-control>
                    <textarea formControlName="note" [readOnly]="absent.readonly"
                              [nzAutosize]="{ minRows: 2, maxRows: 6 }" nz-input
                              placeholder="Notiz schreiben (optional)" nzAutosize></textarea>
        </nz-form-control>
      </nz-form-item>

      <button *ngIf="!absent.readonly"
              nz-button nzType="primary" nzBlock (click)="setAbsent()">Speichern
      </button>


  </form>

  <button *ngIf="absent?.id" class="m-t-10"
          nz-button nzType="link" nzDanger nzBlock (click)="deleteAbsent()">Abwesenheit löschen
  </button>
</nz-spin>
