<div class="text-center">
  <nz-tag *ngIf="mailImportConfigs && mailImportConfigs[0]?.active; else notActive"
          [nzColor]="'green'">Anbindung Aktiv
  </nz-tag>
  <ng-template #notActive>
    <nz-tag>Anbindung Inaktiv</nz-tag>
  </ng-template>


  <img alt="Lexware Office" class="p-30 w-100"
       src="assets/mailimport.png"/>
  <small>
    Importieren Sie E-Mails aus Ihrem Postfach direkt und mit nur einem Klick in die Hausmeisterapp.com
  </small>
  <nz-divider></nz-divider>

</div>
<ng-container *ngIf="true; else notAvailable">
  <button nzBlock (click)="modalVisible = true" nz-button nzType="default"><i nz-icon nzType="setting"
                                                                              nzTheme="outline"></i> Konfigurieren
  </button>

  <a nz-button nzBlock nzType="link" class="m-t-10" href="https://support.netbrick.de/hc/hausmeisterapp/articles/1733231454-e_mail-import" target="_blank"><i nz-icon nzType="question"
                                                                                                                                 nzTheme="outline"></i> Hilfe</a>

  <nz-modal [(nzVisible)]="modalVisible" [nzMaskClosable]="false" [nzClosable]="true" (nzOnCancel)="modalVisible = false" nzWidth="80%" nzTitle="E-Mail Import (IMAP)"
            [nzFooter]="null">
    <ng-container *nzModalContent>
      <nz-spin [nzSpinning]="loading">


        <form nz-form nzLayout="vertical" (submit)="save()">
          <div *ngFor="let config of configForm.controls; let i = index" [formGroup]="config">
            <div nz-row [nzGutter]="12">
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="host">Name / Beschreibung</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="name" type="name" placeholder="mail@adresse.de">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="host">Posteingangsserver / IMAP-Server / Host</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="host" type="text" placeholder="server.example.com">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="7">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="port">IMAP Port</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="port" type="number" placeholder="993">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-label nzFor="tls">TLS Verwenden</nz-form-label>
                  <nz-form-control>
                    <label nz-checkbox formControlName="tls">TLS</label>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row [nzGutter]="12">
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="user">Benutzer</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="user" type="text" placeholder="Benutzername bzw. E-Mailadresse">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="password">Passwort</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="password" type="password" placeholder="Passwort des Postfachs">
                  </nz-form-control>
                </nz-form-item>
              </div>


              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzFor="active">Status</nz-form-label>
                  <nz-form-control>
                    <label nz-checkbox formControlName="active"> E-Mail-Import aktivieren</label>
                    <p class="color-red m-l-20" *ngIf="this.mailImportConfigs[i]._error">Die Konfiguration ist fehlerhaft. Bitte ändern Sie die Werte und versuchen Sie es erneut!</p>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>

          <div class="text-right">
            <a nz-button nzType="link" class="m-r-10" href="https://support.netbrick.de/hc/hausmeisterapp/articles/1733231454-e_mail-import" target="_blank"><i nz-icon nzType="question"
                                                                                                                                                                        nzTheme="outline"></i> Hilfe</a>

            <button nz-button nzType="primary" type="submit" [disabled]="configForm.invalid || configForm.untouched">Speichern</button>
          </div>
        </form>
      </nz-spin>

    </ng-container>
  </nz-modal>
</ng-container>

<ng-template #notAvailable>
  <div class="text-center">
    Bald verfügbar
  </div>
</ng-template>
