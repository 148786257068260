<ng-container *ngIf="!this.authenticationService.currentUserValue?.company?.billing?.timetrackingmodule">

  <div class="container">
    <div class="text-center m-t-30 m-b-40">
      <h2>Zeiterfassung</h2>

      <p>
        Das Modul befindet sich derzeit in einer offenen Testphase. Sie haben jedoch die Möglichkeit, es bereits jetzt
        vollständig freizuschalten und uneingeschränkt zu nutzen. Während dieser Testphase ist die Zeiterfassung in
        Ihrem aktuellen Lizenzmodell kostenfrei. Nach Abschluss der Testphase könnte es zu einer moderaten
        Preisanpassung kommen – selbstverständlich informieren wir Sie frühzeitig darüber. Sie haben dann jederzeit die
        Möglichkeit, das Modul zu kündigen. Etwaige Anpassungen werden sorgfältig kalkuliert und dienen lediglich dazu,
        die entstehenden Mehrkosten zu decken, da die Bereitstellung einer Zeiterfassung technisch anspruchsvoll und
        kostenintensiv ist.
      </p><br>

      <button nz-button nzType="primary" (click)="activateTimetracking()"><i nz-icon nzType="check"
                                                                             nzTheme="outline"></i>
        Zeiterfassung für alle Mitarbeiter aktivieren
      </button>


    </div>

  </div>


</ng-container>


<div class="box-header d-flex align-items-center justify-content-between p-b-10">

  <div>

  </div>


  <div class="d-flex">

    <button class="m-h-10" nz-button nzType="default" (click)="getUsers()"><i
      nz-icon nzType="reload"></i>Aktualisieren
    </button>
    <nz-input-group [nzPrefix]="prefiXTemplate">
      <input nz-input placeholder="Name suchen" nzSize="small" [(ngModel)]="nameSearch"
             (ngModelChange)="filter()">
    </nz-input-group>
    <ng-template #prefiXTemplate>
      <i nz-icon nzType="search" nzTheme="outline"></i>
    </ng-template>
  </div>

</div>

<nz-card>
  <nz-spin [nzSpinning]="userLoading">
    <nz-table #usersTable [nzData]="users" nzSize="small">
      <thead>
      <tr>
        <th colspan="4">Zeiterfassung</th>
        <th colspan="4">Urlaubsanspruch</th>

      </tr>
      <tr>
        <th [nzWidth]="'5%'"></th>
        <th [nzWidth]="'20%'" [nzSortFn]="orderFn.name" [(nzSortOrder)]="sortOrders.name"
            (nzSortOrderChange)="sortChanged($event)">Name
        </th>

        <th>Letzter Stempel</th>
        <th>Zeiterfassung</th>
        <th class="text-center" *ngFor="let year of years">Urlaub {{ year }}</th>
        <th></th>

      </tr>
      </thead>
      <tbody>
      <tr [ngClass]="{ 'noTimeTracking' : !dataItem.renewinterval.module_timetracking}"
          *ngFor="let dataItem of usersTable.data">
        <td style="border-left: 5px solid {{dataItem.color || '#FFFFFF'}}">
          <nz-avatar
            [nzSrc]="dataItem.avatarPath ? backendURL + '/' + dataItem.avatarPath : ''"
            [nzIcon]="dataItem.avatarPath ? backendURL + '/' + dataItem.avatarPath : 'user'"
            style="vertical-align: middle;"
          ></nz-avatar>
        </td>
        <td>
          {{ dataItem.name ? dataItem.name : '' }}
        </td>


        <td class="cursor-pointer" [routerLink]="'/timetracking/' + dataItem.id">
          <i *ngIf="dataItem.timetracking_timestamps[0]?.action === 'KOMMEN'" nz-icon
             [nzType]="dataItem.timetracking_timestamps[0]?.timetracking_type?.icon_web || 'tool'"
             nzTheme="outline"></i>
          <i *ngIf="dataItem.timetracking_timestamps[0]?.action === 'PAUSE'" nz-icon nzType="coffee"
             nzTheme="outline"></i>
          <i *ngIf="dataItem.timetracking_timestamps[0]?.action === 'GEHEN'" nz-icon nzType="logout"
             nzTheme="outline"></i>
          <strong
            [ngClass]="{ 'color-orange': dataItem.timetracking_timestamps[0]?.action === 'KOMMEN','color-blue': dataItem.timetracking_timestamps[0]?.action === 'PAUSE','color-black': dataItem.timetracking_timestamps[0]?.action === 'GEHEN'}">
            {{ dataItem.timetracking_timestamps[0]?.timetracking_type?.name || dataItem.timetracking_timestamps[0]?.action }}
          </strong>
          <small>
            <p class="m-0">
              {{ dataItem?.timetracking_timestamps[0]?.timestamp | date : 'dd.MM.yyy HH:mm' }}
            </p>
          </small>
        </td>
        <td>
          <button class="m-r-10" nz-button nzSize="small"
                  [routerLink]="'/timetracking/' + dataItem.id">
            <i nz-icon nzType="field-time" nzTheme="outline"></i> Stempelzeiten
          </button>
        </td>
        <td class="text-center" *ngFor="let year of years">
          <nz-tag [routerLink]="'/users/' + dataItem.id" [queryParams]="{tab: 'timetracking'}" class="cursor-pointer" *ngIf="getVacationClaimByYear(dataItem.vacationClaims, year); else createNewYear" [nzColor]="getVacationClaimByYear(dataItem.vacationClaims, year)?.expired ? 'red' : ''" [ngClass]="{'expired' : getVacationClaimByYear(dataItem.vacationClaims, year)?.expired}">
            <strong>{{ getVacationClaimByYear(dataItem.vacationClaims, year)?.remaining }}</strong> Tage
          </nz-tag>
          <ng-template #createNewYear>
            <button [disabled]="!dataItem.yearlyVacationClaim" nz-popconfirm
                    nzPopconfirmTitle="Sind Sie sicher, dass Sie für das vergangene Jahr einen Urlaubsanspruch erzeugen möchten?"
                    [nzCondition]="year >= currentYear"
                    (nzOnConfirm)="calcVacaitionClaim(dataItem, year)" nzSize="small" nz-button nzType="dashed" ><i nz-icon nzType="plus-circle" nzTheme="outline"></i></button>
          </ng-template>
        </td>
        <td>


          <a nzSize="small" nz-button nzType="default"
             [href]="'/users/' + dataItem.id + '?tab=timetracking'" target="_blank">
            <i nz-icon nzType="setting" nzTheme="outline"></i> Urlaub
          </a>
        </td>

      </tr>
      </tbody>
    </nz-table>
  </nz-spin>
</nz-card>
