import { Component } from "@angular/core";
import { AsyncPipe } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ApiService } from "../../../services/api.service";

@Component({
    selector: "app-new-release-banner",
    standalone: true,
    imports: [AsyncPipe, NzButtonComponent],
    template: `
        <div class="new-release-banner" [@openClose]="(newReleaseAvailable$ | async) ? 'open' : 'closed'">
            @if (newReleaseAvailable$ | async) {
                <span>Eine neue Version der Anwendung ist verfügbar </span>
                <button class="m-l-15" nz-button nzSize="small" nzType="default" (click)="refresh()">
                    Seite aktualisieren
                </button>
            }
        </div>
    `,
    styles: [
        `
          .new-release-banner {
            background-color: #f18f00;
            color: #000000;
            box-shadow: 5px 5px 10px #e7d6bf;
            padding: 10px 20px;
            text-align: center;
            position: fixed;
            top: -60px;
            left: 0;
            right: 0;
            opacity: 0.8;
            width: fit-content;
            z-index: 1000;
            margin: 0 auto;
          }
        `,
    ],
    animations: [
        trigger("openClose", [
            state("open", style({ transform: "translateY(60px)" })),
            state("closed", style({ transform: "translateY(-60px)" })),
            transition("open => closed", animate("0.4s ease-in")),
            transition("closed => open", animate("0.4s ease-out")),
        ]),
    ],
})
export class NewReleaseBannerComponent {
    newReleaseAvailable$ = this.api.newReleaseAvailable();

    constructor(private api: ApiService) {}

    refresh() {
        window.location.reload();
    }
}
