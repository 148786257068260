<h2 class="m-b-5">Datei bearbeiten</h2>

<form [formGroup]="fileForm" *ngIf="fileForm">
    <div class="m-b-10" nz-col nzSpan="24">
        <nz-form-item nz-col nzSpan="24">
            <nz-form-label nzRequired nzFor="name">Dateiname</nz-form-label>
            <nz-form-control>
                <nz-input-group [nzAddOnAfter]="suffix">
                    <input formControlName="name" placeholder="Name" nz-input>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item nz-col nzSpan="24">
            <nz-form-label nzFor="note">Beschreibung</nz-form-label>
            <nz-form-control>
                    <textarea formControlName="description" [nzAutosize]="{ minRows: 4, maxRows: 6 }" nz-input
                              placeholder="Beschreibung (optional)" nzAutosize></textarea>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *appIsManager nz-col nzSpan="24">
            <nz-form-label nzFor="onlyManager">Optionen</nz-form-label>
            <nz-form-control>
                <label nz-checkbox formControlName="onlyManager" nz-tooltip="">Nur für Manager/Admin sichtbar</label>
            </nz-form-control>
        </nz-form-item>

      <nz-form-item nz-col nzSpan="24">
        <nz-form-label>Verknüpft mit</nz-form-label>
        <nz-form-control>

            <nz-tag *ngIf="file.ticket_id">Ticket</nz-tag>
            <nz-tag *ngIf="file.worklog_id">Arbeitsprotokoll</nz-tag>
            <nz-tag *ngIf="file.object_id">Objekt</nz-tag>
            <nz-tag *ngIf="file.counter_id">Zähler</nz-tag>
            <nz-tag *ngIf="file.counter_status_id">Zählerstand</nz-tag>
            <nz-tag *ngIf="file.personalFileUserId">Personalakte</nz-tag>

        </nz-form-control>
      </nz-form-item>
    </div>
</form>


<div style="height: 30px">
<button *appIsManager class="m-t-10" nzSize="small" nz-button nzType="link" nzDanger (click)="deleteFile(file.id)">Löschen</button>

<button [disabled]="fileForm.invalid" class="m-t-10 pull-right" nzSize="small" nz-button
        nzType="primary" (click)="save()">Speichern </button>
</div>
