export let allNationalities = [
    { code: 'AF', nationality: 'Afghanisch' },
    { code: 'AL', nationality: 'Albanisch' },
    { code: 'DZ', nationality: 'Algerisch' },
    { code: 'AS', nationality: 'Amerikanisch-Samoanisch' },
    { code: 'AD', nationality: 'Andorranisch' },
    { code: 'AO', nationality: 'Angolanisch' },
    { code: 'AG', nationality: 'Antiguanisch und Barbudanisch' },
    { code: 'AR', nationality: 'Argentinisch' },
    { code: 'AM', nationality: 'Armenisch' },
    { code: 'AU', nationality: 'Australisch' },
    { code: 'AT', nationality: 'Österreichisch' },
    { code: 'AZ', nationality: 'Aserbaidschanisch' },
    { code: 'BS', nationality: 'Bahamesisch' },
    { code: 'BH', nationality: 'Bahrainisch' },
    { code: 'BD', nationality: 'Bangladeschisch' },
    { code: 'BB', nationality: 'Barbadianisch' },
    { code: 'BY', nationality: 'Belarussisch' },
    { code: 'BE', nationality: 'Belgisch' },
    { code: 'BZ', nationality: 'Belizisch' },
    { code: 'BJ', nationality: 'Beninisch' },
    { code: 'BT', nationality: 'Bhutanisch' },
    { code: 'BO', nationality: 'Bolivianisch' },
    { code: 'BA', nationality: 'Bosnisch-Herzegowinisch' },
    { code: 'BW', nationality: 'Botswanisch' },
    { code: 'BR', nationality: 'Brasilianisch' },
    { code: 'BN', nationality: 'Bruneiisch' },
    { code: 'BG', nationality: 'Bulgarisch' },
    { code: 'BF', nationality: 'Burkinisch' },
    { code: 'BI', nationality: 'Burundisch' },
    { code: 'CV', nationality: 'Kap-Verdisch' },
    { code: 'KH', nationality: 'Kambodschanisch' },
    { code: 'CM', nationality: 'Kamerunisch' },
    { code: 'CA', nationality: 'Kanadisch' },
    { code: 'CF', nationality: 'Zentralafrikanisch' },
    { code: 'TD', nationality: 'Tschadisch' },
    { code: 'CL', nationality: 'Chilenisch' },
    { code: 'CN', nationality: 'Chinesisch' },
    { code: 'CO', nationality: 'Kolumbianisch' },
    { code: 'KM', nationality: 'Komorisch' },
    { code: 'CG', nationality: 'Kongolesisch (Republik)' },
    { code: 'CD', nationality: 'Kongolesisch (Demokratische Republik)' },
    { code: 'CR', nationality: 'Costa-ricanisch' },
    { code: 'CI', nationality: 'Ivorisch' },
    { code: 'HR', nationality: 'Kroatisch' },
    { code: 'CU', nationality: 'Kubanisch' },
    { code: 'CY', nationality: 'Zyprisch' },
    { code: 'CZ', nationality: 'Tschechisch' },
    { code: 'DK', nationality: 'Dänisch' },
    { code: 'DJ', nationality: 'Dschibutisch' },
    { code: 'DM', nationality: 'Dominicanisch' },
    { code: 'DO', nationality: 'Dominikanisch' },
    { code: 'EC', nationality: 'Ecuadorianisch' },
    { code: 'EG', nationality: 'Ägyptisch' },
    { code: 'SV', nationality: 'Salvadorianisch' },
    { code: 'GQ', nationality: 'Äquatorialguineisch' },
    { code: 'ER', nationality: 'Eritreisch' },
    { code: 'EE', nationality: 'Estnisch' },
    { code: 'SZ', nationality: 'Eswatinisch' },
    { code: 'ET', nationality: 'Äthiopisch' },
    { code: 'FJ', nationality: 'Fidschianisch' },
    { code: 'FI', nationality: 'Finnisch' },
    { code: 'FR', nationality: 'Französisch' },
    { code: 'GA', nationality: 'Gabunisch' },
    { code: 'GM', nationality: 'Gambisch' },
    { code: 'GE', nationality: 'Georgisch' },
    { code: 'DE', nationality: 'Deutsch' },
    { code: 'GH', nationality: 'Ghanaisch' },
    { code: 'GR', nationality: 'Griechisch' },
    { code: 'GD', nationality: 'Grenadisch' },
    { code: 'GT', nationality: 'Guatemaltekisch' },
    { code: 'GN', nationality: 'Guineisch' },
    { code: 'GW', nationality: 'Guinea-bissauisch' },
    { code: 'GY', nationality: 'Guyanisch' },
    { code: 'HT', nationality: 'Haitianisch' },
    { code: 'HN', nationality: 'Honduranisch' },
    { code: 'HU', nationality: 'Ungarisch' },
    { code: 'IS', nationality: 'Isländisch' },
    { code: 'IN', nationality: 'Indisch' },
    { code: 'ID', nationality: 'Indonesisch' },
    { code: 'IR', nationality: 'Iranisch' },
    { code: 'IQ', nationality: 'Irakisch' },
    { code: 'IE', nationality: 'Irisch' },
    { code: 'IL', nationality: 'Israelisch' },
    { code: 'IT', nationality: 'Italienisch' },
    { code: 'JM', nationality: 'Jamaikanisch' },
    { code: 'JP', nationality: 'Japanisch' },
    { code: 'JO', nationality: 'Jordanisch' },
    { code: 'KZ', nationality: 'Kasachisch' },
    { code: 'KE', nationality: 'Kenianisch' },
    { code: 'KI', nationality: 'Kiribatisch' },
    { code: 'KP', nationality: 'Nordkoreanisch' },
    { code: 'KR', nationality: 'Südkoreanisch' },
    { code: 'KW', nationality: 'Kuwaitisch' },
    { code: 'KG', nationality: 'Kirgisisch' },
    { code: 'LA', nationality: 'Laotisch' },
    { code: 'LV', nationality: 'Lettisch' },
    { code: 'LB', nationality: 'Libanesisch' },
    { code: 'LS', nationality: 'Lesothisch' },
    { code: 'LR', nationality: 'Liberianisch' },
    { code: 'LY', nationality: 'Libysch' },
    { code: 'LI', nationality: 'Liechtensteinisch' },
    { code: 'LT', nationality: 'Litauisch' },
    { code: 'LU', nationality: 'Luxemburgisch' },
    { code: 'MG', nationality: 'Madagassisch' },
    { code: 'MW', nationality: 'Malawisch' },
    { code: 'MY', nationality: 'Malaysisch' },
    { code: 'MV', nationality: 'Maledivisch' },
    { code: 'ML', nationality: 'Malisch' },
    { code: 'MT', nationality: 'Maltesisch' },
    { code: 'MH', nationality: 'Marshallisch' },
    { code: 'MR', nationality: 'Mauretanisch' },
    { code: 'MU', nationality: 'Mauritisch' },
    { code: 'MX', nationality: 'Mexikanisch' },
    { code: 'FM', nationality: 'Mikronesisch' },
    { code: 'MD', nationality: 'Moldawisch' },
    { code: 'MC', nationality: 'Monegassisch' },
    { code: 'MN', nationality: 'Mongolisch' },
    { code: 'ME', nationality: 'Montenegrinisch' },
    { code: 'MA', nationality: 'Marokkanisch' },
    { code: 'MZ', nationality: 'Mosambikanisch' },
    { code: 'MM', nationality: 'Myanmarisch' },
    { code: 'NA', nationality: 'Namibisch' },
    { code: 'NR', nationality: 'Nauruisch' },
    { code: 'NP', nationality: 'Nepalesisch' },
    { code: 'NL', nationality: 'Niederländisch' },
    { code: 'NZ', nationality: 'Neuseeländisch' },
    { code: 'NI', nationality: 'Nicaraguanisch' },
    { code: 'NE', nationality: 'Nigrisch' },
    { code: 'NG', nationality: 'Nigerianisch' },
    { code: 'MK', nationality: 'Nordmazedonisch' },
    { code: 'NO', nationality: 'Norwegisch' },
    { code: 'OM', nationality: 'Omanisch' },
    { code: 'PK', nationality: 'Pakistanisch' },
    { code: 'PW', nationality: 'Palauisch' },
    { code: 'PS', nationality: 'Palästinensisch' },
    { code: 'PA', nationality: 'Panamaisch' },
    { code: 'PG', nationality: 'Papua-neuguineisch' },
    { code: 'PY', nationality: 'Paraguayisch' },
    { code: 'PE', nationality: 'Peruanisch' },
    { code: 'PH', nationality: 'Philippinisch' },
    { code: 'PL', nationality: 'Polnisch' },
    { code: 'PT', nationality: 'Portugiesisch' },
    { code: 'QA', nationality: 'Katarisch' },
    { code: 'RO', nationality: 'Rumänisch' },
    { code: 'RU', nationality: 'Russisch' },
    { code: 'RW', nationality: 'Ruandisch' },
    { code: 'KN', nationality: 'Kitts und Nevisianisch' },
    { code: 'LC', nationality: 'Saint-lucianisch' },
    { code: 'VC', nationality: 'Vincentianisch' },
    { code: 'WS', nationality: 'Samoanisch' },
    { code: 'SM', nationality: 'San-marinesisch' },
    { code: 'ST', nationality: 'São-toméisch' },
    { code: 'SA', nationality: 'Saudi-arabisch' },
    { code: 'SN', nationality: 'Senegalesisch' },
    { code: 'RS', nationality: 'Serbisch' },
    { code: 'SC', nationality: 'Seychellisch' },
    { code: 'SL', nationality: 'Sierra-leonisch' },
    { code: 'SG', nationality: 'Singapurisch' },
    { code: 'SK', nationality: 'Slowakisch' },
    { code: 'SI', nationality: 'Slowenisch' },
    { code: 'SB', nationality: 'Salomonenisch' },
    { code: 'SO', nationality: 'Somalisch' },
    { code: 'ZA', nationality: 'Südafrikanisch' },
    { code: 'SS', nationality: 'Südsudanesisch' },
    { code: 'ES', nationality: 'Spanisch' },
    { code: 'LK', nationality: 'Sri-lankisch' },
    { code: 'SD', nationality: 'Sudanesisch' },
    { code: 'SR', nationality: 'Surinamisch' },
    { code: 'SE', nationality: 'Schwedisch' },
    { code: 'CH', nationality: 'Schweizerisch' },
    { code: 'SY', nationality: 'Syrisch' },
    { code: 'TW', nationality: 'Taiwanesisch' },
    { code: 'TJ', nationality: 'Tadschikisch' },
    { code: 'TZ', nationality: 'Tansanisch' },
    { code: 'TH', nationality: 'Thailändisch' },
    { code: 'TL', nationality: 'Osttimoresisch' },
    { code: 'TG', nationality: 'Togolesisch' },
    { code: 'TO', nationality: 'Tonganisch' },
    { code: 'TT', nationality: 'Trinidadisch und Tobagisch' },
    { code: 'TN', nationality: 'Tunesisch' },
    { code: 'TR', nationality: 'Türkisch' },
    { code: 'TM', nationality: 'Turkmenisch' },
    { code: 'TV', nationality: 'Tuvaluisch' },
    { code: 'UG', nationality: 'Ugandisch' },
    { code: 'UA', nationality: 'Ukrainisch' },
    { code: 'AE', nationality: 'Vereinigte Arabische Emirate' },
    { code: 'GB', nationality: 'Britisch' },
    { code: 'US', nationality: 'US-Amerikanisch' },
    { code: 'UY', nationality: 'Uruguayisch' },
    { code: 'UZ', nationality: 'Usbekisch' },
    { code: 'VU', nationality: 'Vanuatisch' },
    { code: 'VA', nationality: 'Vatikanisch' },
    { code: 'VE', nationality: 'Venezolanisch' },
    { code: 'VN', nationality: 'Vietnamesisch' },
    { code: 'YE', nationality: 'Jemenitisch' },
    { code: 'ZM', nationality: 'Sambisch' },
    { code: 'ZW', nationality: 'Simbabweanisch' },
];
