import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../../../../database-models';
import {environment} from '../../../../environments/environment';
import {NzSizeLDSType} from 'ng-zorro-antd/core/types';

@Component({
  selector: 'app-username-avatar',
  templateUrl: './username-avatar.component.html',
  styleUrls: ['./username-avatar.component.css']
})
export class UsernameAvatarComponent implements OnInit {

  public backendURL = environment.apiURL;

  @Input()
  public user: User;

  @Input()
  public width: string;

  @Input()
  public nzSize: NzSizeLDSType = 'small';

  @Input()
  public imageOnly: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public getInitials(): string {
    try {
      return this.user.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
    } catch (e) {

    }

  }

}
