import {Component, ViewChild} from '@angular/core';
import {
  localeDe,
  MbscCalendar,
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  MbscEventCreateEvent,
  MbscEventCreateFailedEvent,
  MbscResource
} from '@mobiscroll/angular';
import {Absent, PublicHoliday, User} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';
import moment from 'moment-timezone';
import {AbsentAddComponent} from '../../absent/absent-add/absent-add.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {EventcalendarBase} from '@mobiscroll/angular/dist/js/core/components/eventcalendar/eventcalendar';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-employee-shift-calendar',
  templateUrl: './employee-shift-calendar.component.html',
  styleUrl: './employee-shift-calendar.component.css'
})
export class EmployeeShiftCalendarComponent {
  @ViewChild('calendar')
  public calendar: MbscCalendar;

  public users: MbscResource[] = [];

  public absents: Absent[] | MbscCalendarEvent[];
  public events: MbscCalendarEvent[];
  public holidays: PublicHoliday[];

  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private message: NzMessageService,
  ) {

  }

  public newEventData() {
    return {
      title: 'Neue Abwesenheit ...',
      timezone: 'Europe/Berlin',
      color: '#b7b7b7'
    };
  }

  public eventSettings: MbscEventcalendarOptions = {
    locale: localeDe,
    theme: 'ios',
    themeVariant: 'light',
    min: moment().startOf('months'),
    clickToCreate: true,
    dragToCreate: true,
    dragToMove: false,
    dragToResize: false,
    eventDelete: false,
    colors: [
      {
        allDay: true,
        background: 'rgb(241,241,241)',
        recurring: {
          repeat: 'weekly',
          weekDays: 'SA,SU'
        },
      }
    ],
    view: {
      timeline: {
        type: 'week',
        size: 4,
        weekNumbers: true,
        eventList: true,
      },
    },
    onEventClick: (event, inst) => {
      if (event.event?.absent) {
        this.editAbsent(event.event.absent, event.event.user);
      }
    },
    onEventCreated: ((args: MbscEventCreateEvent, inst: EventcalendarBase) => {
      console.log(args);
      this.editAbsent({
        start: args.event.start,
        end: args.event.end,
      } as Absent, args.resourceObj);
    }),
    onEventCreateFailed: ((args: MbscEventCreateFailedEvent, inst: EventcalendarBase) => {
      console.log(args);
      if (args.overlap) {
        this.message.error('Es gab eine Überschneidung mit mindestens einer anderen Abwesenheit!')
      }
    })
  };

  async ngOnInit(): Promise<void> {


    let users = await this.api.getUsers().toPromise();
    this.users = users.map(user => {
      user.eventOverlap = false;
      return user;
    });
    this.absents = await this.api.getAbsents().toPromise();
    let absents = this.absents.map((abs: Absent) => {
      return {
        absent: abs,
        start: abs.start,
        end: abs.end,
        color: abs.waitingForApproval ? '#b7b7b7' : abs.user?.color,
        title: (abs.absentType.emoji || '') + ' ' + abs.absentType.name + ' ' + (abs.vacationClaim?.year || ''),
        allDay: true,
        resource: abs.userId,
        user: abs.user,
        waitingForApproval: abs.waitingForApproval,
      } as MbscCalendarEvent;
    });

    this.holidays = await this.api.getPublicHolidays().toPromise();


    let holidays = this.holidays.map((holiday: PublicHoliday) => {
      return {
        start: moment(holiday.date).format('YYYY-MM-DD'),
        end: moment(holiday.date).format('YYYY-MM-DD'),
        allDay: true,
        title: holiday.name,
        color: '#ff0600',
        holiday: holiday
      } as MbscCalendarEvent;
    });
    for (let holiday of this.holidays) {
      this.eventSettings.colors.push({
        date: moment(holiday.date).format(),
        title: holiday.name,
        background: 'rgb(255,200,200)',
      });
    }



    this.events = [...absents];

  }

  public editAbsent(absent: Absent, user: User | MbscResource): void {
    const modal = this.modalService.create<AbsentAddComponent, any>({
      nzContent: AbsentAddComponent,
      nzData: {
        absent: absent,
        user: user
      },
      nzFooter: null,
      nzWidth: 500,
    });

    modal.afterClose.subscribe((data: any) => {
      this.ngOnInit();
    });
  }
}
