import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemeConstantService} from '../../services/theme-constant.service';
import {NzContextMenuService} from 'ng-zorro-antd/dropdown';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Contact, File, Image, User} from '../../../../../../database-models';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../services/authentication.service';
import {ApiService} from '../../../services/api.service';
import {saveAs} from 'file-saver';
import {MessageService} from '../../services/message.service';
import {EditWorklogComponent} from '../edit-worklog/edit-worklog.component';
import {EditFileComponent, ModalData as EditFileModalData} from '../edit-file/edit-file.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})
export class FileListComponent implements OnInit {

  public loading: boolean = false;
  private maxUploadMB = 10;
  public themeColors = this.colorConfig.get().colors;

  public fileTypes = {

    'application/pdf': {
      twoToneColor: this.themeColors.red,
      icon: 'file-pdf'
    },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      twoToneColor: this.themeColors.blue,
      icon: 'file-word'
    },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      twoToneColor: this.themeColors.cyan,
      icon: 'file-excel'
    },
    'application/vnd.ms-powerpoint': {
      twoToneColor: this.themeColors.volcano,
      icon: 'file-ppt'
    },
    'application/txt': {
      twoToneColor: this.themeColors.purple,
      icon: 'file-text'
    },
    'image/gif': {
      twoToneColor: this.themeColors.gold,
      icon: 'file-gif'
    },
    'image/png': {
      twoToneColor: this.themeColors.gold,
      icon: 'file-image'
    },
    'image/jpeg': {
      twoToneColor: this.themeColors.gold,
      icon: 'file-image'
    },
    'application/zip': {
      twoToneColor: this.themeColors.orange,
      icon: 'file-zip'
    }
  };

  private imageTypes = ['image/jpeg', 'image/png', 'image/gif'];

  @Input()
  public hideManagerOnlySelect: boolean = false;

  @Input()
  public fileUploadManagerOnly = false;

  @Input()
  public files: File[] | Image[];

  @Input()
  public uploadEnabled: boolean = true;

  @Input()
  public deleteEnabled: boolean = true;

  @Input()
  public editEnabled: boolean = true;

  @Input()
  public ticketId = '';

  @Input()
  public objectId = '';

  @Input()
  public worklogId = '';

  @Input()
  public personalFileUserId = '';

  @Input()
  public nzGutter = 8;

  @Input()
  public nzSpan = 8;

  @Output()
  public fileUploaded = new EventEmitter<any>();


  public selectedFile = '';

  public currentUser: User;

  public backendURL = environment.apiURL;

  public uploadInProgress = false;


  constructor(
    private colorConfig: ThemeConstantService,
    private nzContextMenuService: NzContextMenuService,
    public authenticationService: AuthenticationService,
    private message: MessageService,
    private modalService: NzModalService,
    private api: ApiService
  ) {
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    if (!this.files) {
      this.getFiles();
    }

    this.files?.forEach(file => {
      file._showFullDescription = false;
    });
  }

  public getFiles(): void {
    this.loading = true;
    this.files = [];
    let where: any = {};

    if (this.ticketId) {
      where.ticket_id = this.ticketId;
    }

    if (this.worklogId) {
      where.worklog_id = this.worklogId;
    }

    if (this.objectId) {
      where.object_id = this.objectId;
    }

    if (this.personalFileUserId) {
      where.personalFileUserId = this.personalFileUserId;
    }

    this.api.getFiles(where).subscribe((files: File[]) => {
      this.files = files;
      this.loading = false;
    }, error => {
      this.message.error('Dateien konnten nicht geladen werden!');
      this.loading = false;
    });
  }


  public close(): void {
    this.nzContextMenuService.close();
  }


  public getFiletype(type: string): any {
    let fileResponse;

    fileResponse = this.fileTypes[type];
    if (!fileResponse) {
      fileResponse = {
        twoToneColor: this.themeColors.grey,
        icon: 'file'
      };
    }

    return fileResponse;
  }

  public fileIsImage(type: string): boolean {
    return this.imageTypes.includes(type);
  }

  public uploadChange(event: any): void {
    if (event.type === 'success') {
      this.message.success('Datei wurde hochgeladen');
      this.fileUploaded.emit(event);
      this.getFiles();
      this.uploadInProgress = false;
    } else if (event.type === 'progress') {
      this.uploadInProgress = true;
    } else if (event.type === 'error') {
      this.uploadInProgress = false;
      this.message.error(event.file.error.error);
    }
  }

  public download(file: File): void {
    this.api.downloadFile(file.id).subscribe(res => {
      saveAs(res, file.name);
    });
  }

  public editFile(data: File): void {
    const newObjectModal = this.modalService.create<EditFileComponent, EditFileModalData>({
      nzContent: EditFileComponent,
      nzData: {
        file: data
      },
      nzWidth: '600px',
      nzFooter: null,
      nzMaskClosable: true,
    });

    newObjectModal.afterClose.subscribe(data => {
      if (data) {
        this.fileUploaded.emit(data);
        this.getFiles();
      }
    });
  }

  public toggleDescriptionView(file: File): void {
    file._showFullDescription = !file._showFullDescription;
  }

  public formatBytes(bytes, decimals = 0): string {
    if (bytes) {
      if (bytes === 0) {
        return '0 Bytes';
      }

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    } else {
      return 'Größe unbekannt';
    }

  }
}
