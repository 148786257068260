<nz-spin [nzSpinning]="uploadInProgress">

  <div class="w-100 m-b-10" *ngIf="uploadEnabled">
    <nz-upload

      #uploadArea
      nzType="drag"
      [nzName]="'upload-file'"
      [nzAction]="backendURL + '/file'"
      [nzAccept]="'.jpg, .jpeg, .png, .gif, .bmp, .pdf, .doc, .docx, .pages, .xls, .xlsx, .numbers'"
      [nzData]="{ticket_id: ticketId, object_id: objectId, worklog_id: worklogId, personalFileUserId: personalFileUserId, onlyManager: fileUploadManagerOnly}"
      [nzHeaders]="{ apiKey: authenticationService.session | async }"
      [nzMultiple]="true"
      (nzChange)="uploadChange($event)"
      [nzShowUploadList]="false"
    >


      <div><i class="font-size-16" nz-icon nzType="plus"></i> Zum Dateien hochladen, <strong>hier klicken</strong> oder
        Datei reinziehen.
        <ng-container *ngIf="!hideManagerOnlySelect">
          <ng-container *appIsManager>


            Sichtbar für:
            <nz-select class="text-bold file-upload-select" (click)="$event.stopPropagation()"
                       [(ngModel)]="fileUploadManagerOnly" nzBorderless>
              <nz-option [nzValue]="false" nzLabel="Jeden"></nz-option>
              <nz-option [nzValue]="true" nzLabel="Manager / Admin"></nz-option>
            </nz-select>
          </ng-container>
        </ng-container>
      </div>
      <p><small>{{ uploadArea.nzAccept }}</small></p>
    </nz-upload>
  </div>

  <div class="file-box" *ngIf="files?.length > 0">
    <nz-table [nzLoading]="loading" [nzTemplateMode]="true">
      <thead>
      <tr>
        <th></th>
        <th>Dateiname</th>
        <th>Informationen</th>
        <th style="width: 120px"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of files">
        <td>
          <img #realImg [hidden]="true" lightbox
               [src]="backendURL + '/' + item.url"
               [imageSrc]="item.url"
               [imageName]="item.name"
               [imageDescription]="item.description"
               [thumbnails]="files"
               [disableControls]="true"/>
          <div class="preview-img" *ngIf="fileIsImage(item.type)" (click)="realImg.click()"
               [style.background-image]="'url(' + backendURL + '/' + item.url + ')'">

          </div>
          <div class="preview-img" *ngIf="!fileIsImage(item.type)">
            <div class="font-size-40" (click)="download(item)">
              <i [nzTwotoneColor]="getFiletype(item.type).twoToneColor" nz-icon
                 [nzType]="getFiletype(item.type).icon"
                 nzTheme="twotone"></i>
            </div>
          </div>
        </td>
        <td>
          <p>{{ item.name }}</p>
          <nz-tag>{{ formatBytes(item.size) }}</nz-tag>
          <nz-tag *ngIf="item.onlyManager" nz-tooltip
                  nzTooltipTitle="Diese Dateien sind nur für Manager & Admin sichtbar"
                  nzColor="red"><i nz-icon nzType="lock" nzTheme="outline"></i> Manager
          </nz-tag>
        </td>
        <td>
          <small>{{ item.user?.name }} am {{ item.createdAt | date:'dd.MM.y HH:mm' }}</small>

          <div>
            <p *ngIf="!item.description"><small>Für diese Datei gibt es keine Beschreibung</small></p>
            <p class="m-0" *ngIf="item.description && !item._showFullDescription && item.description.length > 30">
              {{ item.description | slice:0:30 }}... <a *ngIf="!item._showFullDescription"
                                                        (click)="toggleDescriptionView(item)">Mehr anzeigen</a>
            </p>
            <p class="m-0" *ngIf="item.description && (item._showFullDescription || item.description.length <= 30)">
              {{ item.description }} <a *ngIf="item._showFullDescription" (click)="toggleDescriptionView(item)">weniger
              anzeigen</a>
            </p>

          </div>
        </td>
        <td>
          <button nz-button nzShape="circle" nzType="primary" (click)="download(item)"><i nz-icon nzType="download"></i>
          </button>
          <button nz-button nzShape="circle" class="m-l-5" (click)="editFile(item)"><i nz-icon nzType="edit"></i>
          </button>

        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>

</nz-spin>
