import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';

@Directive({
  selector: '[appIsAdmin]',
  standalone: true
})
export class IsAdminDirective {

  constructor(
    private authService: AuthenticationService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }

  ngOnInit() {


    if (this.authService.currentUserValue.isAdmin) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }

  }

}
