import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Object, Team, User} from "../../../../../../database-models";
import {ApiService} from "../../../services/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import moment from "moment";
import {FormControl, ValidationErrors} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {NzSelectSizeType} from 'ng-zorro-antd/select';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {

  public users: User[];
  public teams: Team[];

  @Input() public selectedUsers: User | User[];
  @Input() public objId: string;
  @Input() public readonly: boolean = false;

  @Output() public selectedUsersChange: EventEmitter<User | User[]> = new EventEmitter<User | User[]>();

  @Input() public onlyManager: boolean = false;
  @Input() public loadTeams: boolean = false;
  @Input() public allowClear: boolean = true;
  @Input() public size: NzSelectSizeType = 'small';
  @Input() public multipleMode = false;
  @Input() public nzMaxTagCount: number = 5;

  public backendURL = environment.apiURL;

  constructor(
      public api: ApiService,
      private message: NzMessageService,
  ) {}

  ngOnInit(): void {
    this.getAllUsers();
  }

  public getAllUsers(): void {
    let filter: any = {};
    if (this.objId) {
      filter.objectId = this.objId
    }
    this.api.getUsers(null, filter).subscribe((value: User[]) => {
      if (this.onlyManager) {
        this.users = value.filter(user => user.isManager);
      } else{
        this.users = value;
      }
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }
}
