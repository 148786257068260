import {Component, inject, Input, OnInit, Optional} from '@angular/core';
import {Absent, AbsentType, PublicHoliday, User} from '../../../../../../../database-models';
import {ApiService} from "../../../../services/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import moment from "moment";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzUploadFile} from "ng-zorro-antd/upload";
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AbsentAddComponent} from '../absent-add/absent-add.component';

export interface ModalData {
	userId: string;
}

@Component({
  selector: 'app-absent',
  templateUrl: './absent.component.html',
  styleUrls: ['./absent.component.scss']
})
export class AbsentComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input() public userId: string;

  public user: User;

  public userAbsents: Absent[];
  public absentTypes: AbsentType[];
  public holidays: PublicHoliday[] = [];

  public isLoading: boolean = false;

  public waitingForApprovalCount = 0;


  constructor(
      public api: ApiService,
      private modalService: NzModalService,
      private notification: NzNotificationService,
      @Optional() private modal: NzModalRef,
      public formBuilder: FormBuilder,
      private message: NzMessageService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.userId ??= this.data.userId;

    this.user = await this.api.getUsers(this.userId).toPromise();
    this.getAbsentTypes();

    if (this.userId) {
      this.getAbsentsByUser();
    }
  }

  public async getAbsentsByUser() {
    this.isLoading = true
    this.userAbsents = await this.api.getAbsentsByUser(this.userId).toPromise();
    this.waitingForApprovalCount = this.userAbsents.filter(abs => abs.waitingForApproval).length;
    this.isLoading = false
  }

  public editAbsent(absent?: Absent): void {
    const modal = this.modalService.create<AbsentAddComponent, any>({
      nzContent: AbsentAddComponent,
      nzData: {
        absent: absent,
        user: this.user
      },
      nzFooter: null,
      nzWidth: 500,
    });

    modal.afterClose.subscribe((data: any) => {
      this.ngOnInit();
    });
  }

  public getAbsentTypes(): void {
    this.isLoading = true;
    this.api.getAbsentTypes().subscribe(types => {
      this.absentTypes = types;
      this.isLoading = false;
    });
  };

  public deleteAbsent(id: string, index: number): void {
    this.modalService.warning({
      nzTitle: '<i>Abwesenheit löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie die Abwesenheit löschen möchten?',
      nzOnOk: () => this.delete(id, index),
      nzCancelText: 'Abbrechen'
    });
  }

  public delete(id: string, index: number): void {
    this.api.deleteAbsent(id).subscribe(value => {
      this.message.info('Eintrag wurde gelöscht')
      this.userAbsents.splice(index, 1);
      this.ngOnInit();
    });
  }


  public close(): void {
    this.modal.destroy();
  }
}
