import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {
  Application,
  Company,
  MailTemplate, PropertyManagement, SepaMandat,
  StatisticTypes,
  SuperAdmin,
  SuperAdminMail,
  User,
  GlobalNews, News, Price, SaldoObject
} from '../../../../../database-models';
import {SuperadminAuthenticationService} from './superadmin-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SuperadminApiService {

  private apiURL = environment.apiURL;
  public user: SuperAdmin;

  constructor(public http: HttpClient, public superadminAuthenticationService: SuperadminAuthenticationService) {

    this.superadminAuthenticationService.currentSuperAdmin.subscribe(user => {
      this.user = user;
    });
  }

  public getTicketsByCompany(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.get(this.apiURL + '/super-admin/ticket/' + id, {headers});
  }

  public getPropertyManagementByCompany(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.get(this.apiURL + '/super-admin/propertymanagement/' + id, {headers});
  }

  public getUsersByCompany(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/company/' + id + '/user', {headers});
  }

  public getUsersByPropertyManagement(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/property-management/' + id + '/user', {headers});
  }

  public getCompany(id?: string, params?: any): Observable<any> {
    let headers;
    if (!id) {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/company', {headers, params});
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/company/' + id, {headers});
    }
  }

  public getAllCompaniesStorage(id?: string): Observable<any> {
    let headers;
    if (!id) {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/storage/company', {headers});
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/storage/company/' + id, {headers});
    }
  }

  public setCompany(data?: Company): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/company/', data, {headers});
  }

  public createCompanyStorage(data?: Company): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/storage/company/', data, {headers});
  }

  public deleteCompanyStorage(companyId?: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.delete(this.apiURL + '/super-admin/storage/company/' + companyId, {headers});
  }

  public deleteCompany(id: string, params?: HttpParams): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/company/' + id, {headers, params});
  }

  public getUsers(query: any): Observable<any> {
    let headers;

      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.post(this.apiURL + '/super-admin/user/query', query, {headers});

  }

  public getRenewInterval(): Observable<any> {
    let headers;

    headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/renewintervals', {headers});

  }

  public deleteUser(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/user/' + id, {headers});
  }

  public getCompaniesBilling(shippingDate: string, shippingEndDate: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.get(this.apiURL + '/super-admin/billing/company/' + shippingDate + '/' + shippingEndDate, {headers});
  }

  public getBillingOverviewCompany(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.get(this.apiURL + '/super-admin/billing/company/', {headers});
  }

  public getOrder(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.get(this.apiURL + '/super-admin/order', {headers});
  }

  public getBillingTransaction(transactionDate: string, transactionEndDate: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.get(this.apiURL + '/super-admin/transaction/' + transactionDate + '/' + transactionEndDate, {headers});
  }

  public getLexInvoices(shippingDate: string, shippingEndDate: string, companyId?: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.get(this.apiURL + '/super-admin/billing/lex-invoice/' + shippingDate + '/' + shippingEndDate + (companyId ? '/' + companyId : ''), {headers});
  }

  public getPropertyManagement(id?: string): Observable<any> {
    let headers;
    if (!id) {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/property-management', {headers});
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/property-management/' + id, {headers});
    }
  }

  public setPropertyManagement(data?: PropertyManagement): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/property-management/', data, {headers});
  }

  public deletePropertyManagement(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/property-management/' + id, {headers});
  }

  public generateNewInvoice(data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/billing/invoice/generate-pdf', data, {headers});
  }

  public setSaldo(data: SaldoObject): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/billing/saldo', data, {headers});
  }

  public createInvoice(data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/billing/invoice/create', data, {headers});
  }

  public getInvoice(id?: string): Observable<any> {
    let headers;
    if (!id) {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/billing/invoice', {headers});
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/billing/invoice/' + id, {headers});
    }
  }

  public getUserById(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/user/' + id, {headers});
  }

  public setUser(data: User): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/user/', data, {headers});
  }

  public getTeam(id: String): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/company/' + id + '/team', {headers});
  }

  public changePassword(data: any, id?: String): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.post(this.apiURL + '/super-admin/user/change-password/' + id, data, {headers});
  }

  public checkAbonnementManager(id: String): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/company/' + id + '/myabonnement/maxmanager', {headers});
  }

  public setApplication(data?: Application): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/application/', data, {headers});
  }

  public getApplication(id?: string): Observable<any> {
    let headers;
    if (!id) {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/application', {headers});
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/application/' + id, {headers});
    }
  }

  public deleteApplication(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/application/' + id, {headers});
  }

  public getStatistic(companyId: String, statistic: StatisticTypes): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/statistics/' + companyId + '/' + statistic, {headers});
  }

  public getMailTemplate(id?: string): Observable<any> {
    let headers;
    if (!id) {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/mailTemplate', {headers});
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/mailTemplate/' + id, {headers});
    }
  }

  public setMailTemplate(data?: MailTemplate): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/mailTemplate/', data, {headers});
  }

  public deleteMailTemplate(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/mailTemplate/' + id, {headers});
  }

  public sendMail(data?: SuperAdminMail): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/mail/', data, {headers});
  }

  public startExpirationMailer(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/mail/expirationMailer', {start: true}, {headers});
  }

  public getMailLogs(company_id?: string): Observable<any> {
    let headers;
    if (!company_id) {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/mailLog', {headers});
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
      return this.http.get(this.apiURL + '/super-admin/mailLog/' + company_id, {headers});
    }
  }

  public setSepaMandat(data?: SepaMandat): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);

    return this.http.post(this.apiURL + '/super-admin/sepa-mandat/', data, {headers});
  }

  public getGlobalNews(id?: string): Observable<any> {
    if (!id) {

      let headers;
      if (this.user.api_key) {
        headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('apikey', this.user.api_key);
      } else {
        headers = new HttpHeaders()
          .set('Content-Type', 'application/json');
      }

      return this.http.get(this.apiURL + '/super-admin/global-news/', {headers});
    } else {
      let headers;
      if (this.user.api_key) {
        headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('apikey', this.user.api_key);
      } else {
        headers = new HttpHeaders()
          .set('Content-Type', 'application/json');
      }

      return this.http.get(this.apiURL + '/super-admin/global-news/' + id, {headers});
    }

  }

  public deleteGlobalNews(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/global-news/' + id, {headers});
  }

  public postGlobalNews(data: GlobalNews, sendPush: boolean): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key)
      .set('push', sendPush ? '1' : '0');
    console.log(data);
    return this.http.post(this.apiURL + '/super-admin/global-news/', data, {headers});
  }

  public getAllActivityLogs(params?: any): Observable<any> {
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/activityLog/', {headers, params});
  }

  public getPriceList(): Observable<any> {
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/prices/', {headers});
  }

  public setPriceList(data: Price): Observable<any> {
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key)
    return this.http.post(this.apiURL + '/super-admin/prices/', data, {headers});
  }

  public deletePrice(id: string): Observable<any> {
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/prices/' + id, {headers});
  }

  public setIntervalOfPrice(data: any): Observable<any> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("apikey", this.user.api_key);

    return this.http.post(this.apiURL + "/super-admin/prices/price-interval-relation", data, {
      headers,
    });
  }

  public deleteIntervalPriceAssociation(priceId: string, renewintervalId: string): Observable<any> {
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('apikey', this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/prices/deleteIntervalPriceAssociation/' + priceId + "/" + renewintervalId, {headers});
  }

  public setInterval(data: any): Observable<any> {
    const headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("apikey", this.user.api_key);

    return this.http.post(this.apiURL + "/super-admin/interval", data, {
      headers,
    });
  }

  public getSessionsByUser(userId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("apikey", this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/session/user/' + userId, {headers});
  }

  public deleteSession(id: string): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("apikey", this.user.api_key);
    return this.http.delete(this.apiURL + '/super-admin/session/' + id, {headers});
  }

  public getPublicHolidays(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.get(this.apiURL + '/holidays/', {headers});
  }

  public getPublicHolidaysFromApi(year: number): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/super-admin/public-holidays/' + year, {headers});
  }

  public getBundesleander(): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('apikey', this.user.api_key);
    return this.http.get(this.apiURL + '/bundesleander/', {headers});
  }

}
