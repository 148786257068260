<div class="p-b-10">


  <div class="text-right">
    <label style="vertical-align: middle" nz-checkbox [(ngModel)]="includedeinactive" (ngModelChange)="getUsers()">deaktivierte
      Mitarbeiter laden</label>


    <button [disabled]="authenticationService.currentUserValue?.company?.billing?.terminated" class="m-h-10" nz-button
            nzType="primary" [routerLink]="'/users/new/'"><i
      nz-icon nzType="plus-circle"></i>Neu
    </button>

    <nz-input-group style="width: 200px" [nzPrefix]="prefiXTemplate">
      <input nz-input placeholder="Name suchen" nzSize="small" [(ngModel)]="nameSearch"
             (ngModelChange)="filter()">
    </nz-input-group>
    <ng-template #prefiXTemplate>
      <i nz-icon nzType="search" nzTheme="outline"></i>
    </ng-template>
  </div>

</div>

<nz-card>
  <nz-spin [nzSpinning]="userLoading">
    <nz-table #usersTable [nzData]="users" nzSize="small">
      <thead>
      <tr>
        <th [nzWidth]="'5%'"></th>
        <th [nzWidth]="'20%'" [nzSortFn]="orderFn.name" [(nzSortOrder)]="sortOrders.name"
            (nzSortOrderChange)="sortChanged($event)">Name
        </th>


        <th [nzSortFn]="orderFn.email" [(nzSortOrder)]="sortOrders.email"
            (nzSortOrderChange)="sortChanged($event)">E-Mail
        </th>
        <th [nzSortFn]="orderFn.username" [(nzSortOrder)]="sortOrders.username"
            (nzSortOrderChange)="sortChanged($event)">Benutzername
        </th>
        <th>Telefonnummer</th>
        <th>Manager</th>
        <th>Admin</th>
        <th>Abrechnung</th>
        <th nz-tooltip="Benachrichtungen"><i class="p-l-25" nz-icon [nzType]="'bell'" nzTheme="outline"></i></th>


      </tr>
      </thead>
      <tbody>
      <tr class="pointer"
          [ngClass]="{ 'inactive' : !dataItem['isActive'] }"
          *ngFor="let dataItem of usersTable.data"
          [routerLink]="'/users/' + dataItem.id">
        <td style="border-left: 5px solid {{dataItem.color || '#FFFFFF'}}">
          <nz-avatar
            [nzSrc]="dataItem.avatarPath ? backendURL + '/' + dataItem.avatarPath : ''"
            [nzIcon]="dataItem.avatarPath ? backendURL + '/' + dataItem.avatarPath : 'user'"
            style="vertical-align: middle;"
          ></nz-avatar>
        </td>
        <td>
          {{ dataItem.name ? dataItem.name : '' }}
        </td>

        <td>
          {{ dataItem.email ? dataItem.email : '' }}
        </td>
        <td>
          {{ dataItem.username ? dataItem.username : '' }}
        </td>
        <td>
          {{ dataItem.phone ? dataItem.phone : '' }}
        </td>
        <td>
          <nz-tag class="m-l-15 m-t-10"
                  [nzColor]="dataItem.isManager ? 'green' : 'red'">
            <i nz-icon [nzType]="dataItem.isManager ? 'check' : 'close'" nzTheme="outline"></i>
          </nz-tag>
        </td>
        <td>
          <nz-tag class="m-l-15 m-t-10"
                  [nzColor]="dataItem.isAdmin ? 'green' : 'red'">
            <i nz-icon [nzType]="dataItem.isAdmin ? 'check' : 'close'" nzTheme="outline"></i>
          </nz-tag>
        </td>
        <td>
          {{ dataItem.renewinterval?.name }}
        </td>
        <td>
          <nz-tag class="m-l-15 m-t-10"
                  nz-tooltip="Benachrichtigungen für jedes neu erstellte Ticket aktiv"
                  *ngIf="dataItem.default_ticket_contributor"
                  [nzColor]="'orange'">
            <i nz-icon [nzType]="'bell'" nzTheme="outline"></i>
          </nz-tag>
        </td>


      </tr>
      </tbody>
    </nz-table>
  </nz-spin>
</nz-card>
