<mbsc-eventcalendar #calendar
                    [data]="events"
                    [options]="eventSettings"
                    [labelContentTemplate]="labelContentTemplate"
></mbsc-eventcalendar>


<ng-template #labelContentTemplate let-data>
  <div [ngClass]="{ 'less-opacity' : data?.original?.waitingForApproval }">
    {{ data?.original?.emoji }}
    <app-username-avatar style="zoom: 0.6; vertical-align: text-bottom;" [imageOnly]="true" *ngIf="data?.original?.user"
                         [user]="data?.original?.user" width="100px"></app-username-avatar>
    <i *ngIf="data?.original?.holiday" nz-icon nzType="calendar" nzTheme="outline"></i>


    {{ data?.original?.title }}

    <small *ngIf="data?.original?.user">{{ data?.original?.user?.name }}</small>
  </div>
</ng-template>

<ng-template #labelTemplate let-data>
  <div [ngClass]="{ 'less-opacity' : data?.original?.waitingForApproval }"
       [ngStyle]="{borderColor: data.color, background: data.color}">
    <app-username-avatar [imageOnly]="true" *ngIf="data?.original?.user" [user]="data?.original?.user"
                         width="100px"></app-username-avatar>
    <i *ngIf="data?.original?.holiday" nz-icon nzType="calendar" nzTheme="outline"></i>
    <i *ngIf="data?.original?.waitingForApproval" nz-icon nzType="question-circle" nzTheme="outline"></i>

    {{ data?.original?.title }}
  </div>
</ng-template>
