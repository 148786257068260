import {Component, OnInit, Optional} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {CalendarConfig} from "../../shared/interfaces/calendar-config";
import {momentTimezone} from "@mobiscroll/angular";
import moment from "moment";
import {Company} from "../../../../../database-models";
import {AuthenticationService} from "../../shared/services/authentication.service";

momentTimezone.moment = moment;

@Component({
  selector: 'app-calendar-settings',
  templateUrl: './calendar-settings.component.html',
  styleUrls: ['./calendar-settings.component.css']
})
export class CalendarSettingsComponent implements OnInit {

  public notAdmin: boolean = true;
  public company: Company;
  public isLoading = false;
  public touched = false;

  public momentPlugin = momentTimezone;
  public config: CalendarConfig = {
    startDay: 1,
    endDay: 0,
    startTime: moment('1900-01-01 00:00').format(),
    endTime: moment('1900-01-01 00:00').format(),
  } as CalendarConfig;

  public days = [
    {
      day: 1,
      name: 'Montag'
    },
    {
      day: 2,
      name: 'Dienstag'
    },
    {
      day: 3,
      name: 'Mittwoch'
    },
    {
      day: 4,
      name: 'Donnerstag'
    },
    {
      day: 5,
      name: 'Freitag'
    },
    {
      day: 6,
      name: 'Samstag'
    },
    {
      day: 0,
      name: 'Sonntag'
    }
  ];

  constructor(
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    public authenticationService: AuthenticationService,
    @Optional() private modal: NzModalRef,
  ) {
  }

  ngOnInit(): void {
    this.getCompany();
    this.notAdmin = !this.authenticationService.currentUserValue.isAdmin;
  }

  public getCompany(): void {
    this.api.getCompanyData().subscribe(value => {
      this.company = value;
      console.log(value)
      if (this.company.calendarConfig) {
        this.config = this.company.calendarConfig;
      }
    })
  }


  public updateSettings(): void {
    this.isLoading = true;
    this.api.setCompanyCalendarConfig(this.config).subscribe((config: any) => {
      console.log(config)
      this.message.success('Erfolgreich gespeichert');
      this.isLoading = false;
      this.authenticationService.loadCurrentUser();
      setTimeout(() => {
        location.reload();
      }, 500);

    }, error => {
      this.message.error('Speichern fehlgeschlagen!');
    });
  }

  public cancel(): void {
    this.modalService.closeAll()
  }

  public reset(): void {
    this.isLoading = true
    this.config = {
        startDay: 1,
        endDay: 0,
        startTime: moment('1900-01-01 00:00').format(),
        endTime: moment('1900-01-01 00:00').format(),
    } as CalendarConfig;
    this.isLoading = false;
    this.updateSettings()
  }


}
