<div class="header" xmlns="http://www.w3.org/1999/html">
  <div class="logo logo-dark">
    <a href="">
      <img src="assets/images/logo/logo-text-only.svg" alt="Logo">
      <img class="logo-fold" src="assets/images/logo/logo-fold.svg" alt="Logo">
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" nzTheme="outline"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" nzTheme="outline"></i>
        </a>
      </li>
    </ul>

    <div style="width: 65%">
      <app-global-search-v2></app-global-search-v2>
    </div>

    <ul class="nav-right pull-right">

      <li *ngIf="user.company?.billing?.timetrackingmodule">
        <a nz-dropdown nzTrigger="click" (click)="timeService.load()" style="min-width: 130px;"
           [ngClass]="{'time-btn' : timeService.getLastAction() === 'KOMMEN' || timeService.getLastAction() === 'PAUSE' || timeService.getLastAction() === 'GEHEN'}"
           [nzDropdownMenu]="popTimetracking" [nzPlacement]="'bottomRight'">
          <ng-container *ngIf="timeService.getLastAction() === 'GEHEN' || !timeService.getLastAction()">
            <i class="m-r-10" nz-icon nzType="field-time" nzTheme="outline"></i> <small
          >{{ timeService.dutyTime | async }}</small>
          </ng-container>

          <ng-container *ngIf="timeService.getLastAction() === 'KOMMEN'">
            <i *ngIf="timeService.getLastAction() === 'KOMMEN'" class="m-r-10" nz-icon [nzType]="timeService.getLastType()?.icon_web || 'tool'"
               nzTheme="outline" style="font-size: medium;"></i>
            <strong><small class="color-orange">{{ timeService.dutyTime | async }}</small></strong>
          </ng-container>

          <ng-container *ngIf="timeService.getLastAction() === 'PAUSE'">
            <i *ngIf="timeService.getLastAction() === 'PAUSE'" class="m-r-10" nz-icon nzType="coffee"
               nzTheme="outline" style="font-size: medium;"></i>
            <strong><small class="color-blue"
            >{{ timeService.breakTime | async }}</small></strong>
          </ng-container>
        </a>
        <nz-dropdown-menu #popTimetracking="nzDropdownMenu" nz-col>
          <div nz-menu class="p-10 pop-notification">
            <app-time-tracking #appTimeTracking></app-time-tracking>
          </div>
        </nz-dropdown-menu>
      </li>

      <li>
        <a  nz-dropdown [nzDropdownMenu]="support" [nzPlacement]="'bottomRight'">
          <nz-badge [nzDot]="globalNewsService?.news?.length > 0">
            <i nz-icon nzType="question-circle" nzTheme="outline"></i>
          </nz-badge>
        </a>
        <nz-dropdown-menu #support="nzDropdownMenu">
          <div nz-menu class="pop-notification">
            <div class="p-10">
              <h5>Kontakt</h5>
              <button nz-button nzBlock nzType="primary" (click)="toggleChat()"><i nz-icon nzType="comment" nzTheme="outline"></i> Hilfe & Kontakt</button>
              <ul class="ant-list">
                <li><i nz-icon nzType="mail" nzTheme="outline"></i> <a href="mailto:service@hausmeisterapp.com"
                                                                       target="_blank">service&#64;hausmeisterapp.com</a></li>
                <li><i nz-icon nzType="phone" nzTheme="outline"></i> <a href="tel:00498131316464" target="_blank">+49 (0)8131
                  316464</a>
                </li>
                <li><i nz-icon nzType="book" nzTheme="outline"></i> <a href="https://hilfe.hausmeisterapp.com" target="_blank">Wiki / Helpcenter / Anleitungen</a>
                </li>
                <li>
                  Ihre Kunden-ID: <strong class="m-l-10" style="letter-spacing: 2px">{{authservice.currentUserValue?.company?.code}}</strong>
                </li>
              </ul>
            </div>

            <div *ngIf="globalNewsService.news?.length > 0" class="overflow-y-auto border-top" style="max-height: 300px">
              <nz-list class="cursor-pointer" [nzDataSource]="globalNewsService.news" [nzRenderItem]="helpTpl" [nzItemLayout]="'horizontal'">
                <ng-template #helpTpl let-item>
                  <nz-list-item class="p-10" (click)="globalNewsService.createNotification(item)">
                    <nz-list-item-meta
                      [nzAvatar]="notificationIcon" [nzDescription]="globalNewsService.getTypeTitle(item.type)"
                    >
                      <ng-template #notificationIcon>
                        <nz-avatar nzIcon="notification" [style.background-color]="globalNewsService.getColor(item.type)"></nz-avatar>
                      </ng-template>
                      <nz-list-item-meta-title>
                        {{ item.title }}
                      </nz-list-item-meta-title>
                    </nz-list-item-meta>
                  </nz-list-item>
                </ng-template>
              </nz-list>
            </div>
          </div>
        </nz-dropdown-menu>

      </li>

      <li title="Neu">
        <a class="m-h-5" nz-dropdown [nzDropdownMenu]="shortcutsMenu" [nzPlacement]="'bottomRight'">
          <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        </a>
        <nz-dropdown-menu #shortcutsMenu="nzDropdownMenu">
          <ul nz-menu>
            <li (click)="ticketService.newTicketModal()" nz-menu-item>
              <a><i nz-icon nzType="inbox" nzTheme="outline"></i> Ticket erstellen</a>
            </li>
            <li routerLink="/tasks/new" nz-menu-item>
              <a><i nz-icon nzType="schedule" nzTheme="outline"></i> Aufgabe erstellen</a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
      <li>
        <a nz-dropdown nzTrigger="click" (click)="notificationService.getNotifications()"
           [nzDropdownMenu]="popNotification" [nzPlacement]="'bottomRight'">
          <nz-badge [nzCount]="notificationService.unreadNotifications">
            <i nz-icon nzType="bell" theme="outline"></i>
          </nz-badge>
        </a>
        <nz-dropdown-menu #popNotification="nzDropdownMenu" nz-col>
          <div nz-menu class="pop-notification" nz-row>
            <div nz-col class="m-l-10 m-t-10 m-b-10 d-flex justify-content-between align-items-center">
              <p class="text-dark font-weight-semibold m-b-0">
                <i nz-icon nzType="bell" theme="outline"></i>
                <span class="m-l-10">Benachrichtigungen</span>
              </p>
            </div>

            <div nz-row>
              <a class="m-l-10 m-b-5" (click)="notificationService.setAllNotificationToRead()">
                <small>Alle als gelesen markieren</small>
              </a>

              <a style="margin-left: 110px;" [routerLink]="'/notifications/my-notifications'">
                <small>Alle Anzeigen</small>
              </a>
            </div>

            <nz-divider class="p-0 m-0"></nz-divider>

            <div class="overflow-y-auto scrollbar-wrapper" style="max-height: 300px">
              <nz-list class="ant-list-item-links" [nzDataSource]="notificationService.notificationList"
                       [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                <ng-template #item let-item>
                  <nz-list-item [class.notification-read]="!item.read">
                    <a [href]="item.actionURL" (click)="notificationService.setNotificationToRead(item)">
                      <div class="d-flex" >
                        <div class="m-l-15">
                          <p class="m-b-0 text-bold">{{ item.title }}</p>
                          <p class="m-b-0" [innerHTML]="item.text"></p>
                          <p class="m-b-0"><small>Vor {{ getTime(item.createdAt) }}</small></p>
                        </div>
                      </div>
                    </a>
                  </nz-list-item>
                </ng-template>
              </nz-list>
            </div>
          </div>
        </nz-dropdown-menu>
      </li>

      <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
                      [nzPlacement]="'bottomRight'">
                    <nz-avatar
                      [nzSrc]="user.avatarPath ? backendURL + '/' + user.avatarPath : ''"
                      [nzIcon]="user.avatarPath ? backendURL + '/' + user.avatarPath : 'user'"
                      style="vertical-align: middle;"
                    ></nz-avatar>
                </span>
        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu class="p-b-15 p-t-20">
            <li class="p-h-20 p-b-15 m-b-10 border-bottom">
              <div class="d-flex m-r-50">
                <nz-avatar
                  [nzSrc]="user.avatarPath ? backendURL + '/' + user.avatarPath : ''"
                  [nzIcon]="user.avatarPath ? backendURL + '/' + user.avatarPath : 'user'"
                  nzSize="large"
                  style="vertical-align: middle;"
                ></nz-avatar>
                <div class="m-l-10">
                  <p class="m-b-0 text-dark font-weight-semibold">{{ user.name }}</p>
                  <p class="m-b-0 opacity-07">{{ user.email }}</p>
                </div>
              </div>
            </li>
            <li nz-menu-item>
              <a [routerLink]="'/account'" class="p-v-5 d-flex align-items-center justify-content-between">
                <div>
                  <i class="opacity-04 font-size-16" nz-icon nzType="lock" nzTheme="outline"></i>
                  <span class="m-l-10">Account Einstellungen</span>
                </div>
                <i class="font-size-10" nz-icon nzType="right" nzTheme="outline"></i>
              </a>
            </li>
            <li nz-menu-item (click)="authservice.logout()">
              <a class="p-v-5 d-flex align-items-center justify-content-between">
                <div>
                  <i class="opacity-04 font-size-16" nz-icon nzType="logout" nzTheme="outline"></i>
                  <span class="m-l-10">Logout</span>
                </div>
                <i class="font-size-10" nz-icon nzType="right" nzTheme="outline"></i>
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
      <li *ngIf="false">
        <a (click)="quickViewToggle()">
          <i nz-icon nzType="appstore" nzTheme="outline"></i>
        </a>
        <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible"
                   nzPlacement="right" (nzOnClose)="quickViewToggle()">
          <app-quick-view></app-quick-view>
        </nz-drawer>
      </li>
    </ul>
  </div>
</div>
