import {Component, ElementRef, HostListener, inject, Injector, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {MasterTableComponent} from '../master-table/master-table.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import moment from 'moment';
import * as uuid from 'uuid';
import {ApiService} from '../../../services/api.service';
import {
  CheckListTemplate,
  Consumable,
  Consumable_Ticket, consumableGroups, Contact,
  News,
  Object, ObjectGroup,
  Room,
  SharedWorkLog,
  Task, Ticket,
  User,
  WorkLog,
  WorklogBulUpdate,
} from '../../../../../../database-models';
import {MbscCalendarEvent} from '@mobiscroll/angular';
import {EditTaskComponent, ModalData as EditTaskModalData} from '../task/edit-task/edit-task.component';
import {ActivatedRoute, Router} from '@angular/router';
import {saveAs} from 'file-saver';
import {WorklogEditChecklistComponent, ModalData as WorklogEditChecklistModalData} from '../checklist/worklog-edit-checklist/worklog-edit-checklist.component';
import {SignatureWorklogComponent} from '../signature-worklog/signature-worklog.component';
import {PrintService} from '../../services/print.service';
import {PrintWorklogComponent, ModalData as PrintWorklogModalData} from '../../template/print/print-worklog/print-worklog.component';
import {NzTableComponent} from 'ng-zorro-antd/table';
import {GlobalSearchComponent} from '../global-search/global-search/global-search.component';
import {AuthenticationService} from '../../services/authentication.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {WorklogShareListComponent} from '../../template/print/print-worklog/worklog-share-list/worklog-share-list.component';
import {FileBrowserComponent, ModalData as FileBrowserModalData} from '../file-browser/file-browser.component';
import da from '@mobiscroll/angular/dist/js/i18n/da';
import {FileListComponent} from '../file-list/file-list.component';
import {LexofficeService} from '../../services/lexoffice.service';
import {group} from '@angular/animations';
import {groupBy} from '../../../../../../helper-functions';
import {environment} from '../../../../environments/environment';

export interface ModalData {
	worklogId: string;
  room?: Room;
  ticket?: Ticket;
  consumed?: Consumable_Ticket[];
}

@Component({
  selector: 'app-edit-worklog',
  templateUrl: './edit-worklog.component.html',
  styleUrls: ['./edit-worklog.component.scss'],
})

export class EditWorklogComponent extends MasterTableComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @ViewChild('completedServices')
  private completedServicesTable: NzTableComponent<any>;

  @ViewChild('globalSearchTASKComponent')
  public globalSearchTASKComponent: GlobalSearchComponent;

  @ViewChild('globalSearchTICKETComponent')
  public globalSearchTICKETComponent: GlobalSearchComponent;

  @ViewChild('worklogShareListComponent')
  public worklogShareListComponent: WorklogShareListComponent;

  @ViewChild('fileListComponent')
  public fileListComponent: FileListComponent;

  @Input() public room: Room;
  @Input() public ticket: Ticket;
  @Input() public worklogId: string;
  @Input() public consumed: Consumable_Ticket[];

  public worklog: WorkLog;
  public currentUser: User;
  public reference: Object | Contact;
  public users: User[];
  public objects: Object[];
  public checkListTemplates: CheckListTemplate[];
  public sharedWorklog: SharedWorkLog[];
  public consumables: Consumable_Ticket[];
  public allConsumables: Consumable[] = [];
  public availableConsumables: Consumable[] | any;

  public worklogForm: FormGroup;

  public serviceTemplates: string[] = [];
  public objectId: string;
  public linkedTask: string;
  public linkedTicket: string;
  public title = 'Arbeitsprotokoll bearbeiten';

  public isTouched: boolean = false;
  public loading = false;
  public showTaskSearch = false;
  public showTicketSearch = false;

  public worklogConsumable = {
    number: 1,
  } as Consumable_Ticket;

  constructor(
    injector: Injector,
    public formBuilder: FormBuilder,
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    public route: ActivatedRoute,
    public router: Router,
    private printService: PrintService,
    public authenticationService: AuthenticationService,
    public lexofficeService: LexofficeService,
    @Optional() private modal: NzModalRef
  ) {
    super(injector);
    this.route.params.subscribe((params) => {
      if (!this.worklogId) {
        this.worklogId = params['id'] || null;
      }

    });
  }

  public ngOnInit() {
  this.room ??= this.data?.room;
  this.ticket ??= this.data?.ticket;
  this.worklogId ??= this.data?.worklogId;
  this.consumed ??= this.data?.consumed;

    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    if (this.worklogId) {
      //console.log('Worklog ID: ' + this.worklogId);
      this.getWorklog();
      this.getConsumables();
    } else {
      this.router.navigate(['worklogs']);
    }

    this.getUsers();
    this.getObjects();

    if (this.objectId) {
      this.getTemplatesByObjectId();
    }
  }

  private initForm(): void {
    this.worklogForm = this.formBuilder.group({
        description: [this.worklog.description],
        comment: [this.worklog.comment],
        internalNote: [this.worklog.internalNote],
        creator: [this.worklog.creator, [Validators.required]],
        involved_users: [this.worklog.involved_users],
        object: [this.worklog.object],
        start: [this.worklog.start, [Validators.required]],
        end: [this.worklog.end],
      },
      {
        validators: this.dateLessThan('start', 'end'),
      }
    );
  }

  public getWorklog(): void {
    this.loading = true;
    this.api.getWorklog(this.worklogId).subscribe((worklog: WorkLog) => {
        this.loading = false;
        this.worklog = worklog;

        this.availableConsumables = [];
        if (this.availableConsumables) {
          for (const item of this.allConsumables) {
            if (!worklog?.consumed.find(cons => cons.id === item.id)) {
              this.availableConsumables.push(item);
            }
          }
        }
        this.availableConsumables = groupBy(this.availableConsumables, 'group');

        this.reference = worklog?.object || worklog?.contact;
        this.initForm();
        this.getSharedWorklogs();
        if (this.worklog.finalized) {
          setTimeout(() => this.worklogForm.disable(), 500);
        }
      },
      (error) => {
        this.loading = false;
        this.modal.close();
      }
    );
  }

  private dateLessThan(from: string, to: string): any {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];

      if (moment(f.value) > moment(t.value)) {
        return {
          dates: 'Das Ende darf nicht vor dem Start liegen',
        };
      }
      return {};
    };
  }

  public getServiceTemplates(): void {
    this.api.getServiceTemplates().subscribe(
      (serviceTemplates: any[]) => {
        //console.log('Service Templates: ', serviceTemplates);
        for (const template of serviceTemplates) {
          this.serviceTemplates.push(template.name);
        }
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Service Templates konnten nicht geladen werden.');
      }
    );
  }

  public getUsers(): void {
    this.api.getUsers().subscribe(
      (employees: User[]) => {
        this.users = employees;
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Mitarbeiter konnten nicht geladen werden');
      }
    );
  }

  public getObjects(): void {
    this.api.getObjects().subscribe(
      (objects: Object[]) => {
        this.objects = objects;
        // this.initForm();
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Objekte konnten nicht geladen werden');
      }
    );
  }

  public save(): void {
    this.loading = true;
    const worklogToSave: WorkLog = this.worklogForm.getRawValue();
    worklogToSave.id = this.worklog.id;
    worklogToSave.object_id = this.worklog.object_id;
    worklogToSave.contact_id = this.worklog.contact_id;
    worklogToSave.employee_id = worklogToSave.creator.id;
    worklogToSave.signatures = this.worklog.signatures;
    worklogToSave.room_id = this.worklog.room?.id || null;

    worklogToSave.task_id = this.worklog.task_id;
    worklogToSave.ticket_id = this.worklog.ticket_id;

    this.api.setWorklog(worklogToSave).subscribe(
      (worklog: WorkLog) => {
        if (worklog.id) {
          this.router.navigate(['worklogs/' + worklog.id]);
        }
        this.loading = false;
        this.message.success('Erfolgreich gespeichert');
        this.getWorklog();
      },
      (error) => {
      }
    );
  }

  public setFinal(): void {
    this.modalService.warning({
      nzTitle: '<i>Arbeitsprotokoll finalisieren?</i>',
      nzContent:
        'Sind Sie sicher, dass Sie das Arbeitsprotokoll finalisieren möchten?<br><br>Wenn Sie ein Protokoll finalisieren, kann es nicht mehr bearbeitet werden.',
      nzOnOk: () => this.setFinalized(),
      nzCancelText: 'Abbrechen',
    });
  }

  // Auf Signature prüfen ist aber müll
  // public setFinal(): void {
  //   if (this.worklog.signatures?.length <= 0 || this.worklog.signatures === null) {
  //     this.modalService.warning({
  //       nzTitle: "<i>Arbeitsprotokoll ohne Unterschrift finalisieren?</i>",
  //       nzContent: "Wenn Sie ein Protokoll finalisieren, kann es nicht mehr bearbeitet werden.",
  //       nzOkText: "Finalisieren",
  //       nzOnOk: () => this.setFinalized(),
  //       nzCancelText: "Unterschrift hinzufügen",
  //       nzOnCancel: () => this.createNewSignature(true),
  //       nzMaskClosable: true
  //     });
  //   } else {
  //     this.modalService.warning({
  //       nzTitle: "<i>Arbeitsprotokoll finalisieren?</i>",
  //       nzContent:
  //           "Sind Sie sicher, dass Sie das Arbeitsprotokoll finalisieren möchten?<br><br>Wenn Sie ein Protokoll finalisieren, kann es nicht mehr bearbeitet werden.",
  //       nzOnOk: () => this.setFinalized(),
  //       nzCancelText: "Abbrechen",
  //     });
  //   }
  // }

  public setFinalized(): void {
    this.worklog.finalized = new Date().toISOString();
    this.api.setFinal(this.worklog).subscribe(
      (worklog: WorkLog) => {
        this.message.success('Arbeitsprotokoll wurde finalisiert');
        this.getWorklog();
      },
      (error) => {
      }
    );
  }

  public cancel(): void {
    if (this.worklog.finalized) {
      this.modalService.closeAll();
    } else {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent:
          'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => this.modalService.closeAll(),
      });
    }
  }

  public deleteWorklog(): void {
    this.modalService.warning({
      nzTitle: '<i>Arbeitsprotokoll löschen</i>',
      nzContent:
        'Sind Sie sicher, dass Sie das Arbeitsprotokoll löschen möchten? Alle Daten gehen verloren.',
      nzOnOk: () => this.delete(),
      nzCancelText: 'Abbrechen',
    });
  }

  private delete(): void {
    this.api.deleteWorklog(this.worklog.id).subscribe((news: News) => {
      this.message.success('Arbeitsprotokoll erfolgreich gelöscht.');
      this.modal.close();
    });
  }

  public editTask(task?: Task | MbscCalendarEvent): void {
    const newObjectModal = this.modalService.create<EditTaskComponent, EditTaskModalData>({
      nzContent: EditTaskComponent,
      nzData: {
        task: task,
      },
      nzWidth: '1100px',
      nzFooter: null,
      nzClosable: true,
      nzOnCancel: (instance) => {
      },
      nzOnOk: (instance) => {
      },
    });
  }

  public downloadPDF(): void {
    const msgid = this.message.loading('PDF wird erzeugt ...', { nzDuration: 0 }).messageId;
    this.api.downloadWorklogPDF({id: this.worklog.id}).subscribe((res) => {
      let name = 'AP_' + moment(this.worklog.start).format('DD-MM-YYYY') + (this.worklog.object ? '_' + this.worklog.object.key : '') + '.pdf';
      saveAs(res, name);
      this.message.remove(msgid);
    }, error => {
      this.message.remove(msgid);
      this.message.error('PDF Konnte nicht erzeugt werden');

    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public getTemplatesByObjectId(): void {
    this.api.getCheckListTemplatesByObjectId(this.objectId).subscribe(
      (templates: CheckListTemplate[]) => {
        this.checkListTemplates = templates;
        this.tableData.data = this.checkListTemplates;
      },
      (onerror) => {
        this.message.error('Checklisten konnten nicht geladen werden');
      }
    );
  }

  public createNewChecklist(): void {
    const editModal = this.modalService.create<WorklogEditChecklistComponent, WorklogEditChecklistModalData>({
      nzContent: WorklogEditChecklistComponent,
      nzData: {
        worklog: this.worklog,
      },
      nzFooter: null,
      nzWidth: 700,
    });

    editModal.afterClose.subscribe((data: any) => {
      this.getWorklog();
    });
  }

  public getConsumables(): void {
    this.api.getConsumables().subscribe((consumables: Consumable[]) => {
      this.allConsumables = consumables;
    }, (onerror) => {
      console.log(onerror);
      this.message.error('Verbrauchsgegenstände konnten nicht geladen werden');
    });
  }


  public setWorklogConsumable(value?: Consumable_Ticket): void {
    let toSend = {} as Consumable_Ticket;

    if (value) {
      toSend = value;
    } else {
      toSend.number = this.worklogConsumable.number;
      toSend.consumableId = this.worklogConsumable.consumable.id;
      toSend.ticketId = this.worklog?.ticket_id;
      toSend.worklogId = this.worklog.id;
      toSend.objectId = this.worklog.object_id;
    }


    toSend.number = +toSend.number.toString().replace(',', '.');
    this.api.createWorklogConsumable(toSend).subscribe((consumable_ticket: Consumable_Ticket) => {
      this.message.success('Erfolgreich gespeichert');
      if (value) {
        value._isTouched = false;
      }
      this.getWorklog();
      this.worklogConsumable.consumable = null;
      this.worklogConsumable.number = 1;
    });
  }

  public deleteConsumable(consumable: Consumable_Ticket): void {
    this.modalService.warning({
      nzTitle: '<i>Verbrauchsgegenstand löschen</i>',
      nzContent:
        'Sind Sie sicher, dass Sie den Verbrauchsegenstand in diesem Ticket löschen möchten?',
      nzOnOk: () => this.deleteCon(consumable),
      nzCancelText: 'Abbrechen',
    });
  }

  private deleteCon(consumable: Consumable_Ticket): void {
    this.api.deleteConsumableTicketAssociation(consumable.id).subscribe(
      (cons: Consumable) => {
        this.message.success('Verbrauchsgegenstand erfolgreich gelöscht.');
        this.getWorklog();
      },
      (error) => {
        this.message.error('Verbrauchsgegenstand konnte nicht gelöscht werden');
      }
    );
  }

  public createNewSignature(setFinal?: boolean): void {
    const editModal = this.modalService.create({
      nzContent: SignatureWorklogComponent,
      nzFooter: null,
      nzWidth: 700,
      nzOnOk: (instance) => {
        //console.log(instance);
        if (!this.worklog.signatures) {
          this.worklog.signatures = [];
        }
        this.worklog.signatures.push(instance.signature);
        this.save();
      },
    });

    editModal.afterClose.subscribe((data: any) => {
      if (setFinal && this.worklog.signatures.length > 0) {
        this.setFinalized();
      }
      this.getWorklog();
    });
  }

  public deleteSignature(index: number) {
    this.modalService.warning({
      nzTitle: '<i>Unterschrift löschen?</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie die Unterschrift löschen möchten?',
      nzOnOk: () => this.deleteSigna(index),
      nzCancelText: 'Abbrechen',
    });
  }

  public deleteSigna(index) {
    this.worklog.signatures.splice(index, 1);
    this.save();
  }

  public referenceSelected(value: Object | Contact): void {
    if (value && value.member) {
      switch (value.member) {
        case 'Object':
          this.worklog.object_id = value.id;
          this.worklog.contact_id = null;
          this.worklogForm.markAsTouched();
          break;
        case 'Contact':
          this.worklog.contact_id = value.id;
          this.worklog.object_id = null;
          this.worklogForm.markAsTouched();
          break;
      }
    } else {
      this.worklog.contact_id = null;
      this.worklog.contact = null;
      this.worklog.object_id = null;
      this.worklog.object = null;
      this.worklog.room = null;
      this.worklog.room_id = null;
      this.worklogForm.markAsTouched();
    }
  }

  public saveCompletedServices(): void {
    const toSave: WorkLog = {
      id: this.worklog.id,
      completedServices: this.worklog.completedServices
    } as WorkLog;
    this.api.setWorklogCompletedServices(toSave).subscribe(value => {
      this.getWorklog();
      this.isTouched = false;
    });
  }

  public deleteCservice(index: number): void {
    this.modalService.warning({
      nzTitle: '<i>Eintrag löschen</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diesen Eintrag löschen möchten?',
      nzOnOk: () => this.deleteCompletedService(index),
      nzCancelText: 'Abbrechen',
    });
  }

  public deleteCompletedService(index: number): void {
    this.worklog.completedServices.splice(index, 1);
    this.message.success('Eintrag erfolgreich gelöscht.');
    this.saveCompletedServices();
  }

  public hidebtn(): boolean {
    return !this.isTouched;
  }

  public addOwnService(): void {
    const newid = uuid.v4();
    if (!this.worklog.completedServices) {
      this.worklog.completedServices = [];
    }
    this.worklog.completedServices.push({
      id: newid,
      name: ''
    });
    this.completedServicesTable.nzPageIndex = this.completedServicesTable.nzTotal;
  }


  public shareWorklog(): void {
    const editModal = this.modalService.create<PrintWorklogComponent, PrintWorklogModalData>({
      nzContent: PrintWorklogComponent,
      nzData: {
        worklog: this.worklog,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    editModal.afterClose.subscribe((data: any) => {
      this.getWorklog();
      this.worklogShareListComponent.getSharedWorklogs();
    });
  }

  public navigateToTicketPage(ticketId: string) {
    this.router.navigate(['/tickets', ticketId]);
    this.modalService.closeAll();
  }


  public bulkUpdateBilled(value: any): void {
    const bulk: WorklogBulUpdate = {
      ids: this.worklog.id,
      field: 'billed',
      value: value
    } as unknown as WorklogBulUpdate;

    this.api.updateWorkLogBulk(bulk).subscribe(res => {
      this.message.success('Das Update war erfolgreich!');
      this.getWorklog();
    }, error => {
      this.message.error('Das Update war nicht erfolgreich!');
    });
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.worklog.completedServices, event.previousIndex, event.currentIndex);
    this.isTouched = true;
  }

  public getSharedWorklogs(): void {
    this.api.getSharedWorklog(this.worklog.id).subscribe((sharedWorklogs: SharedWorkLog[]) => {
      this.sharedWorklog = sharedWorklogs;
    });
  }

  public replaceDotWithComma(number) {
    return number?.toString().replace('.', ',');
  }

  public openTab(link: string) {
    window.open(link, '_blank');
  }

  public deleteTaskLink(data: WorkLog): void {
    this.modalService.warning({
      nzTitle: '<i>Verknüpfung löschen</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diese Verknüpfung löschen möchten?',
      nzOnOk: () => this.api.deleteTaskLink(data).subscribe(res => {
        this.getWorklog();
      }),
      nzCancelText: 'Abbrechen',
    });
  }

  public deleteTicketLink(data: WorkLog): void {
    this.modalService.warning({
      nzTitle: '<i>Verknüpfung löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie diese Verknüpfung löschen möchten?',
      nzOnOk: () => this.api.deleteTicketLink(data).subscribe(res => {
        this.getWorklog();
      }),
      nzCancelText: 'Abbrechen',
    });
  }

  public sendToLexOffice(): void {
    this.lexofficeService.worklogToInvoice([this.worklog]).then(lexInvoice => {
      this.getWorklog();
    }, reason => {

    })
  }

  public openFileBrowser(): void {
    const modal = this.modalService.create<FileBrowserComponent, FileBrowserModalData>({
      nzContent: FileBrowserComponent,
      nzData: {
        ticketId: this.worklog.ticket_id,
      },
      nzFooter: null,
      nzWidth: 700,
    });

    modal.afterClose.subscribe((data: any) => {
      if (data) {
        for (let file of data) {
          file.worklog_id = this.worklog.id;
        }
        this.api.updateFileBulk(data).subscribe(res => {
          this.fileListComponent.getFiles();
        })
        //console.log(data);
      }
    });
  }

  public linkTask(task: Task): void {
    this.worklog.task_id = task.id;
    this.save();
  }

  public linkTicket(ticket: Ticket): void {
    this.worklog.ticket_id = ticket.id;
    this.save();
  }

  public toggleTaskSearch() {
    this.showTaskSearch = !this.showTaskSearch;
    this.showTicketSearch = false;
    setTimeout(() => {
      this.globalSearchTASKComponent.searchFocus();
    }, 100);
  }

  public toggleTicketSearch() {
    this.showTicketSearch = !this.showTicketSearch;
    this.showTaskSearch = false;
    setTimeout(() => {
      this.globalSearchTICKETComponent.searchFocus();
    }, 100);
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent) {
    this.showTicketSearch = false;
    this.showTaskSearch = false;
  }

  @HostListener('keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent) {
    this.showTicketSearch = false;
    this.showTaskSearch = false;
  }

  protected readonly consumableGroups = consumableGroups;
  protected readonly environment = environment;
    protected readonly moment = moment;
}
