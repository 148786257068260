<div *minAppversion="3">
  <div nz-row>
    <div nz-col nzSpan="18">
      <h2>Abwesenheiten</h2>
      <nz-tag nzColor="red" *ngIf="waitingForApprovalCount > 0">Es stehen <strong>{{ waitingForApprovalCount }}</strong>
        Abwesenheitsanträge zur
        Genehmigung aus
      </nz-tag>
    </div>

    <div nz-col nzSpan="6">
      <button class="pull-right" nz-button nzType="primary" type="button" (click)="editAbsent()">
        <i nz-icon nzType="plus-circle"></i> Neu
      </button>
    </div>
  </div>
  <nz-spin [nzSpinning]="isLoading">
    <ng-container *ngIf="userAbsents?.length <= 0">
      <span><p>Aktuell liegen keine Einträge vor.</p></span>
    </ng-container>

    <ng-container *ngIf="userAbsents?.length > 0">
      <nz-table nzSize="small" [nzData]="['']">
        <thead>
        <tr>
          <th>Abwesenheit</th>
          <th>Mitarbeiter</th>
          <th>Von</th>
          <th>Bis</th>
          <th nz-tooltip nzTooltipTitle="betroffene Arbeitstage">Tage</th>
          <th>Info</th>
          <th style="width: 5%;"></th>
          <th style="width: 5%;"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let absent of userAbsents; let i = index"
            [ngClass]="{'background-yellow text-bold' : absent.waitingForApproval}">
          <td>
            {{ absent.absentType?.emoji }}
            <nz-tag class="m-l-5"
                    [nzColor]="absent.absentType?.color">{{ absent.absentType?.name }} {{ absent.vacationClaim?.year }}
            </nz-tag>
          </td>
          <td>{{ absent.user?.name }}</td>
          <td>
            {{ absent.start | date:'dd.MM.y' }} <br>
          </td>
          <td> {{ absent.end | date:'dd.MM.y' }}</td>
          <td>{{ absent.neededWorkDays || '' }}</td>
          <td>
            <i nz-tooltip [nzTooltipTitle]="'Erstellt am ' + (absent.createdAt | date: 'dd.MM.y H:mm') + ' Uhr'"
               nz-icon nzType="calendar" nzTheme="outline"></i>
            <i class="m-l-5" nz-tooltip [nzTooltipTitle]="absent.note"
               *ngIf="absent.note" nz-icon nzType="file-done" nzTheme="outline"></i>
            <span class="m-l-5" *ngIf="absent.halfDay" nz-tooltip nzTooltipTitle="½ Tag(e)">½</span>
          </td>
          <td style="padding-left: 15px;">
            <ng-container *ngIf="absent.approved">
              <nz-tag [nzColor]="'green'">Genehmigt</nz-tag>
            </ng-container>
            <ng-container *ngIf="absent.rejected">
              <nz-tag [nzColor]="'red'">Abgelehnt</nz-tag>
            </ng-container>
            <ng-container *ngIf="!absent.approved && !absent.rejected">
              <button class="p-0" nzSize="small" nz-button nzType="link" type="button"
                      (click)="editAbsent(absent)">
                <i nz-icon nzType="question" nzTheme="outline"></i> Ausstehend
              </button>
            </ng-container>
          </td>
          <td style="padding-left: 15px;">
            <button nzSize="small" nz-button nzType="link" nzDanger nzShape="circle" type="button"
                    (click)="deleteAbsent(absent.id, i)">
              <i nz-icon nzType="delete"></i></button>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </ng-container>

  </nz-spin>
</div>
