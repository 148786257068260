<nz-spin [nzSpinning]="isLoading">

  <h3>
    Zählerstand Übersicht {{ counter?.counterNumber }}

  </h3>
  <p>{{ counter.object?.name }}</p>

  <ng-container *ngIf="counterStatuses?.length <= 0">
    <p>Aktuell liegen keine Einträge vor</p>
  </ng-container>
  <nz-table #counterStatusNzTable [nzData]="counterStatuses" nzSize="small" *ngIf="counterStatuses?.length > 0"
            (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
    <thead>
    <tr>
      <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)">
      </th>
      <th style="width: 160px">Bild</th>
      <th>Zählerstand</th>
      <th>Datum der Ablesung</th>
      <th>Abgelesen von</th>
      <th>Notiz</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let status of counterStatuses; let i = index">
      <td
        [nzChecked]="setOfCheckedId.has(status.id)"
        (nzCheckedChange)="onItemChecked(status.id, $event)"
        (dblclick)="$event.stopPropagation()"
      ></td>
      <td>

        <ng-container *ngIf="status?.files?.length <= 0; else image">
          <nz-upload
            [nzName]="'upload-file'"
            [nzAction]="backendURL + '/file'"
            [nzFileType]="'image/png,image/jpeg,image/jpg,image/gif,image/bmp'"
            [nzData]="{user_id: userId, counter_id: counter.id, counterStatusId: status?.id}"
            [nzAccept]="'.png, .jpeg, .jpg, .gif'"
            [nzHeaders]="{ apiKey: authenticationService.session | async }"
            (nzChange)="onChange($event)"
            [nzShowUploadList]="false"
          >
            <p style="padding: 0; margin: 0;">
              <i nz-icon nzType="camera" nzTheme="outline" class="m-r-5"></i><a>Bild hinzufügen</a>
            </p>
          </nz-upload>
        </ng-container>
        <ng-template #image>
          <app-lightbox [images]="status?.files" [showDateAsCaption]="false" [disableControls]="true" [maxHeight]="80" [nzSpan]="24"></app-lightbox>

        </ng-template>

      </td>
      <td>
        <strong>{{ status.count }}</strong> {{status.counter?.counterType?.unit}}
      </td>
      <td>
        {{ status.readingDate | date:'dd.MM.y' }} <p>{{ moment(status.readingDate).fromNow() }}</p>
      </td>
      <td>
        {{ status?.user?.name }}
      </td>
      <td>{{ status.note ? status.note : '' }}</td>
      <td>
        <button *appIsManager nzSize="small" nz-button nzType="default" nzDanger nzShape="circle"
                (click)="deleteCounterStatus(status.id, i)">
          <i nz-icon nzType="delete"></i></button>
      </td>
    </tr>
    </tbody>
  </nz-table>


  <button class="m-t-15" *ngIf="counterStatuses?.length > 0" [disabled]="setOfCheckedId.size <= 0" nzSize="small"
          nzType="default" nz-button
          nz-dropdown [nzDropdownMenu]="menu"
          nzPlacement="bottomCenter"><i nz-icon nzType="file-pdf" nzTheme="outline"></i> Exportieren
  </button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="exportReport(false)"><i class="p-r-5" nz-icon nzType="file-pdf"
                                                        nzTheme="outline"></i>
        ( {{ setOfCheckedId.size }} ) Zählerstand Exportieren
      </li>
      <li nz-menu-item (click)="exportReport(true)"><i class="p-r-5" nz-icon nzType="file-pdf"
                                                       nzTheme="outline"></i>
        ( {{ setOfCheckedId.size }} ) Zählerstand Exportieren mit Bildern
      </li>
    </ul>
  </nz-dropdown-menu>
</nz-spin>

