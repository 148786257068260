<div class="common-layout {{ 'is-' + selectedHeaderColor }}"
    [ngClass]="{
        'is-folded': isFolded,
        'is-side-nav-dark' : isSideNavDark,
        'is-expand' : isExpand
    }"
>
    <app-header></app-header>
    <app-sidenav></app-sidenav>
    <div class="page-container">
        <div class="main-content">
            <app-new-release-banner></app-new-release-banner>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
