<nz-card class="m-t-10 card-no-padding">

  <nz-table nzTableLayout="fixed" nzSize="default" #monthTable
            [nzData]="timeTrackingMonths">
    <thead>
    <tr>
      <th>Monat</th>
      <th>Arbeitszeit</th>
      <th>Pausenzeit</th>
      <th nz-tooltip [nzTooltipTitle]="'Bezahlte / Entschuldigte Abwesenheit'">Abwesenheit</th>
      <th style="width: 80px">Soll</th>
      <th style="width: 205px">Summe</th>

      <th></th>
      <th style="width: 60px" class="text-center" nz-tooltip [nzTooltipTitle]="'Monat aus der Zeitkonto-Berechnung ausschließen'" ><i nz-icon nzType="minus-circle" nzTheme="outline"></i></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let timeTrackingMonth of monthTable.data" [ngClass]="{

              'today' : moment(timeTrackingMonth.start).isSame(moment(), 'month'),
              'disabled' : timeTrackingMonth.ignored}">
      <td>
        <a (click)="selectMonth.emit(timeTrackingMonth.start)">{{ moment(timeTrackingMonth.year + (timeTrackingMonth.month.toString().padStart(2, '0'))).locale('de').format('MMMM YYYY') }}</a> </td>
      <td>{{ timetracking.convertTime(timeTrackingMonth.worktime, true) }}</td>
      <td>{{ timetracking.convertTime(timeTrackingMonth.breaktime, true) }}</td>
      <td>{{ timetracking.convertTime(timeTrackingMonth.absenttime, true) }}</td>
      <td>{{ timetracking.convertTime(timeTrackingMonth.needed, true) }}</td>
      <td>
        <strong>{{ timetracking.convertTime(timeTrackingMonth.total, true) }}</strong>
        <small class="p-l-20 color-green"
               *ngIf="timeTrackingMonth.saldo > 0">{{ timeTrackingMonth.saldo ? '+' + timetracking.convertTime(timeTrackingMonth.saldo) : '' }}</small>
        <small class="p-l-20 color-light-grey" *ngIf="timeTrackingMonth.saldo === 0">00:00</small>
        <small class="p-l-20 color-red"
               *ngIf="timeTrackingMonth.saldo < 0">{{ timeTrackingMonth.saldo ? timetracking.convertTime(timeTrackingMonth.saldo) : '' }}</small>

        <i *ngIf="timeTrackingMonth.locked" class="m-l-10 pull-right" nz-icon nzType="lock" nzTheme="outline"></i>


        <nz-progress [nzShowInfo]="false" [nzPercent]="calcPercent(timeTrackingMonth)" nzSize="small"></nz-progress>
      </td>


      <td>
        <button nz-popconfirm
                nzPopconfirmTitle="Möchen Sie den Monat wirklich abschließen?"
                (nzOnConfirm)="lock(timeTrackingMonth)" nzSize="small"
                nz-button nzType="primary" *ngIf="timeTrackingMonth._canLock"><i nz-icon nzType="lock"
                                                                                 nzTheme="outline"></i> Abschließen
        </button>
      </td>
      <td>
        <nz-switch nz-tooltip [nzTooltipTitle]="'Monat aus der Zeitkonto-Berechnung ausschließen'" nzSize="small" [(ngModel)]="timeTrackingMonth.ignored" (ngModelChange)="setMonth(timeTrackingMonth)"></nz-switch>
      </td>
    </tr>
    </tbody>
  </nz-table>

</nz-card>
