import {Component, inject, Input, OnInit, ViewChild} from '@angular/core';
import {Counter, CounterStatus, File, Object} from "../../../../../../../database-models";
import {ApiService} from "../../../../services/api.service";
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import {FormBuilder} from "@angular/forms";
import {AuthenticationService} from "../../../services/authentication.service";
import {environment} from "../../../../../environments/environment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {DatePipe} from "@angular/common";

import moment from 'moment';
import {NzTableComponent} from 'ng-zorro-antd/table';
import { ExportService } from 'src/app/shared/services/export.service';

export interface ModalData {
  counter: Counter;
}

@Component({
  selector: 'app-counter-status-historie-list',
  templateUrl: './counter-status-historie-list.component.html',
  styleUrls: ['./counter-status-historie-list.component.css']
})
export class CounterStatusHistorieListComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input() public counter: Counter;
  public userId: string;
  public counterStatuses: CounterStatus[];
  public isLoading = false;
  public backendURL = environment.apiURL;

  public checked = false;
  public indeterminate = false;
  public listOfCurrentPageData: CounterStatus[] = [];
  public setOfCheckedId = new Set<string>();

  constructor(
      public api: ApiService,
      private modal: NzModalRef,
      private message: NzMessageService,
      public formBuilder: FormBuilder,
      private modalService: NzModalService,
      private authenticationService: AuthenticationService,
      private datePipe: DatePipe,
      private exportService: ExportService
  ) {
  }

  ngOnInit(): void {
    this.counter ??= this.data.counter;
    this.getCounterStatuses();
    this.userId = this.authenticationService.currentUserValue.id
  }

  public getCounterStatuses(): void {
    this.isLoading = true;
    this.api.getCounterStatusbyCounter(this.counter.id).subscribe(value => {
      this.counterStatuses = value;
      this.isLoading = false;
    })
  }

  public deleteCounterStatus(statusId: string, index: number): void {
    this.modalService.warning({
      nzTitle: 'Zählerstand löschen',
      nzContent: 'Sind Sie sicher, dass Sie den Zählerstand löschen möchten?',
      nzOnOk: () => this.delete(statusId, index),
      nzCancelText: 'Abbrechen'
    });
  }

  public delete(statusId: string, index: number): void {
    this.api.deleteCounterStatus(statusId).subscribe(value => {
      this.counterStatuses.splice(index, 1)
      this.message.info('Zählerstand wurde gelöscht');
    })
  }


  public onChange(event: any): void {
    console.log(event);
    if (event.type === 'success') {
      this.message.success('Bild wurde hochgeladen')
      this.getCounterStatuses();
    } else if (event.type === 'error') {
      this.message.error(event.file.message);
      this.getCounterStatuses();
    }
  }

  public onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData.forEach(({id}) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  public updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  public refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = this.listOfCurrentPageData.some(({id}) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  public onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public onCurrentPageDataChange(listOfCurrentPageData: CounterStatus[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  public async exportReport(withImg: boolean): Promise<void> {
    let exportJSON: any[] = [];
    let doc = new jsPDF();

    const selectedCountersStatus = [];
    for (let id of this.setOfCheckedId) {
      let item = this.counterStatuses.find(sC => sC.id === id);
      selectedCountersStatus.push(item);
    }

    for (const status of selectedCountersStatus) {
      if (status.files[0] && status.files[0].url && withImg) {
        const canvas = await this.exportService.getImageBase64(this.backendURL + '/' + status?.files[0]?.url);
        status.files[0]._base64image = canvas;
      }

      const row = {
        'Datum': this.datePipe.transform(status.readingDate, 'dd.MM.y'),
        'Zählerstand': status.count + ' (' + this.counter.counterType.unit + ')',
        Notiz: status.note,
        ...(withImg && {'Bild': ''})
      };
      exportJSON.push(row);
    }


    doc.text(this.counter.counterType.name + ': ' + this.counter.counterNumber, 15, 10);
    doc.setFontSize(12)
    doc.text(this.counter.note || '-', 15, 18);
    doc.text(this.counter?.object?.name, 15, 26);


    autoTable(doc, {
      head: [Object.keys(exportJSON[0])],
      body: exportJSON.map(obj => Object.values(obj)),
      margin: {top: 35},
      headStyles: {
        fillColor: [255, 143, 0]
      },
      bodyStyles: {
        minCellHeight: withImg ? 60 : 10
      },
      columnStyles: {
        0: {cellWidth: withImg ? 25 : "auto"},
        1: {cellWidth: withImg ? 35 : "auto"},
        2: {cellWidth: withImg ? 50 : "auto"},
        3: {cellWidth: withImg ? 70 : "auto"},
      },
      didDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 3) {
          const base64image = selectedCountersStatus[data.row.index]?.files[0]?._base64image;
          if (base64image) {
            doc.addImage(base64image.base64, 'JPEG', data.cell.x + 6, data.cell.y + 2, base64image.width / 10, base64image.height / 10)
          } else {
            doc.text('', data.cell.x + 2, data.cell.y + 5);
          }
        }
      },
    });

    doc.save(this.counter.counterNumber + '.pdf')
  }

  protected readonly moment = moment;
}
