import {Component, Injector, Input, OnInit} from '@angular/core';
import {MasterTableComponent} from '../master-table/master-table.component';
import {Abonnment, User} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import {Router} from '@angular/router';
import {EditUserComponent} from '../edit-user/edit-user.component';
import {environment} from '../../../../environments/environment';
import {TimetrackingService} from "../../services/timetracking.service";
import {AuthenticationService} from '../../services/authentication.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public backendURL = environment.apiURL;

  public userOriginal: User[] = [];
  public users: User[] = [];


  public abonnement: Abonnment;

  public title = 'Mitarbeiter';
  public nameSearch: string;
  public userLoading = false;
  public includedeinactive = false;

  public sortOrders = {
    name: null,
    username: null,
    email: null
  };

  public constructor(
    private api: ApiService,
    public router: Router,
    public authenticationService: AuthenticationService
  ) {
  }

  public ngOnInit(): void {
    this.getUsers();
    const storedOrderFn = localStorage.getItem('tableheaderUser');
    if (storedOrderFn) {
      this.sortOrders = JSON.parse(storedOrderFn);
    }
  }

  public sortChanged(change: any) {
    localStorage.setItem('tableheaderUser', JSON.stringify(this.sortOrders));
  }

  public orderFn = {
    name: (a, b) => a.name?.localeCompare(b.name),
    username: (a, b) => a.username?.localeCompare(b.username),
    email: (a, b) => a.email?.localeCompare(b.email),
  }

  public getUsers(): void {
    this.userLoading = true;
    this.api.getUsers(null, {includeInactive: this.includedeinactive}).subscribe((users: User[]) => {
      this.userOriginal = users;
      this.users = users;
      this.userLoading = false;
    }, onerror => {
      console.log(onerror);
      // TODO
    });
  }

  public filter() {
    if (this.nameSearch) {
      this.users = this.userOriginal.filter(users =>
        users.name.toLowerCase().indexOf(this.nameSearch.toLowerCase()) !== -1);
    } else {
      this.users = this.userOriginal;
    }
  }
}
