import {Component, inject, Input, Optional} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {Absent, AbsentType, PublicHoliday, User} from '../../../../../../../database-models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzMessageService} from 'ng-zorro-antd/message';
import moment from 'moment/moment';

export interface ModalData {
  user: User;
  absent: Absent;
}

@Component({
  selector: 'app-absent-add',
  templateUrl: './absent-add.component.html',
  styleUrl: './absent-add.component.css'
})
export class AbsentAddComponent {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input() public user: User;

  public userAbsents: Absent[];
  public absentTypes: AbsentType[];
  public absent: Absent;
  public holidays: PublicHoliday[] = [];

  public allHolidays: any = [];
  public holidayLabel: any = [];
  public rangeSelected: any = [];
  public label: any = [];
  public color: any = [];

  public isLoading: boolean = false;
  private date: Date = new Date();

  public absentForm: FormGroup;

  constructor(
    public api: ApiService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    @Optional() private modal: NzModalRef,
    public formBuilder: FormBuilder,
    private message: NzMessageService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.user ??= this.data.user;
    this.absent ??= this.data.absent;
    this.getAbsentTypes();



    if (this.user) {
      this.getAbsentsByUser();
      this.user = await this.api.getUsers(this.user.id).toPromise();
    }
    if (!this.absent) {
      this.absent = {
        absentTypeId: 'LEAVE',
        userId: this.user.id,
        approved: false,
        rejected: false,
        start: this.date.toISOString()
      } as Absent;


    }

    this.absent.daterange = [this.absent.start, this.absent.end];
    this.initForm();
  }

  public initForm(): void {
    this.absentForm = this.formBuilder.group(
      {
        daterange: [this.absent.daterange, [Validators.required]],
        halfDay: [this.absent.halfDay, [Validators.required]],
        approvalState: [(this.absent.approved ? 'approved' : (this.absent.rejected ? 'rejected' : 'open'))],
        absentTypeId: [this.absent.absentTypeId || 'LEAVE'],
        note: [this.absent.note],
      }
    );
  }

  public setAbsent(): void {

    this.isLoading = true;
    const toSend = this.absentForm.getRawValue();
    if (toSend.halfDay) {
      toSend.start = moment(toSend.daterange).format('YYYY-MM-DD');
      toSend.end = toSend.start;
    } else {
      toSend.start = moment(toSend.daterange[0]).format('YYYY-MM-DD');
      toSend.end = toSend.daterange[1] ? moment(toSend.daterange[1]).format('YYYY-MM-DD') : toSend.start;
    }


    toSend.approved = toSend.approvalState === 'approved';
    toSend.rejected = toSend.approvalState === 'rejected';
    toSend.userId = this.absent.userId = this.user.id;
    toSend.id = this.absent.id;

    this.api.setAbsent(toSend).subscribe(value => {
      this.modal.close(value);
      this.message.success('Abwesenheit wurde gespeichert');
      this.isLoading = false;
    }, error => {
      this.notification.error('Fehler', error.error?.error);
      this.isLoading = false;
    });

  };

  public async getAbsentsByUser() {
    this.isLoading = true;
    this.userAbsents = await this.api.getAbsentsByUser(this.user.id).toPromise();
    const invalids: any = [];
    const labels: any = [];
    let colors: any = [];
    for (const absent of this.userAbsents.filter(abs => !abs.rejected)) {
      if (absent.id === this.absent?.id) {
        continue;
      }
      invalids.push({start: absent.start, end: absent.end});
      labels.push({
        start: absent.start,
        end: absent.end,
        text: absent.approved ? ((absent.halfDay ? '&#189; ' : '&#10003; ') + absent.absentType.name) : (absent.halfDay ? '&#189; ' : '') + absent.absentType.name,
        textColor: 'rgba(86,86,86,0.8)'
      });
      colors.push({
        start: absent.start,
        end: absent.end,
        background: absent.absentType.lightcolor,
        cellCssClass: absent.approved ? '' : 'absent-stripes-bg'
      });
    }
    this.rangeSelected = invalids;
    this.label = labels;
    this.color = colors;


    let holidays = await this.api.getPublicHolidays().toPromise();
    this.holidays = holidays;
    const allHoliday: any = [];
    const holidayLabel: any = [];
    for (const holiday of this.holidays) {
      allHoliday.push({
        start: holiday.date,
        end: holiday.date,
        background: '#ffd9e1'
      });

      holidayLabel.push({
        start: holiday.date,
        end: holiday.date,
        text: holiday.name,
        textColor: 'rgba(86,86,86,0.8)'
      });
    }
    this.allHolidays = allHoliday;
    this.holidayLabel = holidayLabel;
    this.color = this.color.concat(this.allHolidays);
    this.rangeSelected = this.rangeSelected.concat(this.allHolidays);
    this.label = this.label.concat(this.holidayLabel);

    this.isLoading = false;
  }

  public getAbsentTypes(): void {
    this.isLoading = true;
    this.api.getAbsentTypes().subscribe(types => {
      this.absentTypes = types;
      this.isLoading = false;
    });
  };

  public deleteAbsent(): void {
    this.modalService.warning({
      nzTitle: '<i>Abwesenheit löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie die Abwesenheit löschen möchten?',
      nzOnOk: () => {
        this.api.deleteAbsent(this.absent.id).subscribe(value => {
          this.message.info('Abwesenheit wurde gelöscht');
          this.modal.destroy('deleted');
        });
      },
      nzCancelText: 'Abbrechen'
    });
  }


  public close(): void {
    this.modal.destroy();
  }
}
