<div *minAppversion="3">

  <nz-alert nzType="warning"
            [nzMessage]="msg">
    <ng-template #msg>
      <nz-tag nzColor="red">BETA Phase</nz-tag>
      Diese Komponente befindet sich aktuell noch in der Weiterentwicklung. SIe haben jedoch berits jetzt die Möglichkeit die Funktionen zu nutzen.<br>
      Planen und genehmigen Sie über den Kalender die Abwesenheiten Ihrer Mitarbeiter. Sehen Sie auf einen Blick, welche Mitarbeiter verfügbar sind.
    </ng-template>
  </nz-alert>

  <mbsc-eventcalendar #calendar
                      [data]="events"
                      [options]="eventSettings"
                      [resources]="users"
                      [resourceTemplate]="myResourceTemplate"
                      [scheduleEventContentTemplate]="labelContentTemplate"
                      [extendDefaultEvent]="newEventData"
  ></mbsc-eventcalendar>

  <ng-template #labelContentTemplate let-data>
    <div [ngClass]="{ 'less-opacity' : data?.original?.waitingForApproval }">

      {{ data?.original?.title }}

      <small *ngIf="data?.original?.waitingForApproval">(Beantragt)</small>
    </div>
  </ng-template>

  <ng-template #myResourceTemplate let-resource>
    <div class="mbsc-flex">
      <app-username-avatar nzSize="default" [user]="resource" [imageOnly]="true"></app-username-avatar>
      <div class="m-l-10 mds-employee-shifts-cont">
        <div class="mds-employee-shifts-name">{{ resource.name }}</div>
        <div class="mds-employee-shifts-title">{{ resource.title }}</div>
      </div>
    </div>
  </ng-template>

</div>
