import {SideNavInterface} from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
  {
    path: '',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: []
  },
  {
    path: '/objects',
    title: 'Objekte',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'home',
    submenu: []
  },
  {
    path: '/contacts',
    title: 'Kontakte',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'solution',
    submenu: [
      {
        path: '/contacts',
        title: 'Kontakte',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'solution',
        submenu: []
      },
      {
        path: '/residents',
        title: 'Bewohner',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user',
        submenu: []
      }
    ]
  },
  {
    path: '/tasks',
    title: 'Aufgaben',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'schedule',
    submenu: []
  },
  {
    path: '/tickets',
    title: 'Tickets',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'inbox',
    submenu: []
  },

  {
    path: '/worklogs',
    title: 'Arbeitsprotokolle',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'file-done',
    submenu: [],
    managerOnly: true
  },
  {
    path: '/maps',
    title: 'Karten & GPS',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'compass',
    submenu: [
      {
        path: '/maps',
        title: 'Karte',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'compass',
        submenu: []
      },
      {
        path: '/maps/routes',
        title: 'Routen Planer',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'node-index',
        submenu: []
      },
    ],
    managerOnly: true,
    moduleName: 'MAPS'
  },
  {
    path: '/servicecatalog',
    title: 'Leistungskataloge',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'book',
    submenu: [],
    managerOnly: true
  },

  {
    path: '/consumables',
    title: 'Verbrauchsmaterial',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'bulb',
    managerOnly: true,
    submenu: [
      {
        path: '/consumables',
        title: 'Verwalten',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'profile',
        submenu: [],
        managerOnly: true
      },
      {
        path: '/consumables/stats',
        title: 'Auswertung',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'line-chart',
        submenu: [],
        managerOnly: true
      }
    ],
  },
  {
    path: '/counter',
    title: 'Zähler',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: [],
    managerOnly: true
  },
  {
    path: '/news',
    title: 'News',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'mail',
    submenu: [
      {
        path: '/news',
        title: 'Bewohner-News',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'mail',
        submenu: []
      },
      {
        path: '/employee-news',
        title: 'Mitarbeiter-News',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'team',
        submenu: []
      }
    ],
  },
  {
    path: '/users',
    title: 'Personal',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'team',
    submenu: [
      {
        path: '/users',
        title: 'Mitarbeiter',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user',
        submenu: [],
        adminOnly: true,
        allowIfExpired: true
      },
      {
        path: '/team',
        title: 'Teams',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'team',
        submenu: []
      },
      {
        path: '/timetracking',
        title: 'Zeiterfassung',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'clock-circle',
        submenu: []
      },
      {
        path: '/absence',
        title: 'Abwesenheiten',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-delete',
        submenu: []
      },
      {
        path: '/user-object-permission',
        title: 'Berechtigungssystem',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'security-scan',
        submenu: [],
        permissionSystem: true
      }
    ],
    adminOnly: true,
    allowIfExpired: true
  },
  {
    path: '/company',
    title: 'Mein Unternehmen',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'shop',
    submenu: [],
    adminOnly: true,
    allowIfExpired: true
  }
];
